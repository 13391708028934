export const formatUrl = (url: string) => (url.startsWith('http') ? url : `http://${url}`);

export const toUrlParams = (params: Record<string, string | string[] | number>): string => {
	const queryString = Object.keys(params)
		.map(key => {
			const value = params[key];
			if (value === undefined || value === null) {
				return '';
			} else if (Array.isArray(value)) {
				return value.map(v => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
			} else {
				return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
			}
		})
		.filter(Boolean)
		.join('&');
	return queryString;
};
