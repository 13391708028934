import { ORDER_DIRECTION } from '@agentero/components';
import { ListCarriersRequest } from '@agentero/grpc-clients/grpc/carrier-fe';
import {
	CarrierItem,
	CarriersSearch,
	parseCarrierAppointmentRequestStatusToProto,
	parseCarrierOrderBy,
	parseCarriersList
} from '@agentero/models';
import { parseLineOfInsuranceToProto, parseYesNoToBoolean } from '@agentero/models/shared';
import { handleGrpcResponse } from '@agentero/service-errors';

import { carrierFrontendService } from 'packages/services/grpcClients';

import { AgenteroUserData } from '../../useAgQuery';

export type FetchCarriersArgs = {
	search: CarriersSearch;
};

export const fetchCarrierList = ({
	token,
	userId,
	search
}: AgenteroUserData & FetchCarriersArgs) => {
	return new Promise<CarrierItem[]>((resolve, reject) => {
		const request = new ListCarriersRequest();

		const filters = new ListCarriersRequest.Filters();
		filters.setSearchText(search.filters.searchTerm);

		if (search.filters.appointmentStatus) {
			filters.setAppointmentStatus(
				parseCarrierAppointmentRequestStatusToProto(search.filters.appointmentStatus)
			);
		}

		if (search.filters.isAppointable !== undefined) {
			filters.setIsAppointable(parseYesNoToBoolean(search.filters.isAppointable));
		}

		if (search.filters.isBindWithAgentero !== undefined) {
			filters.setIsBindingWithAgentero(parseYesNoToBoolean(search.filters.isBindWithAgentero));
		}

		if (search.filters.lineOfInsurance !== undefined) {
			filters.setLineOfInsurance(parseLineOfInsuranceToProto(search.filters.lineOfInsurance));
		}

		if (search.filters.isInstantAppointment !== undefined) {
			filters.setIsInstantAppointment(parseYesNoToBoolean(search.filters.isInstantAppointment));
		}

		if (search.filters.isTransferable !== undefined) {
			filters.setIsTransferable(parseYesNoToBoolean(search.filters.isTransferable));
		}

		request.setFilters(filters);

		if (search.order) {
			const order = new ListCarriersRequest.OrderBy();
			order.setDirection(
				search.order.direction === ORDER_DIRECTION.ASC
					? ListCarriersRequest.OrderBy.Direction.DIRECTION_ASC
					: ListCarriersRequest.OrderBy.Direction.DIRECTION_DESC
			);

			order.setField(parseCarrierOrderBy(search.order.field));

			request.setOrderBy(order);
		}

		carrierFrontendService.listCarriers(
			request,
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}

				const carriers = parseCarriersList(response.toObject());
				resolve(carriers);
			}
		);
	});
};
