import { FC, PropsWithChildren, ReactNode } from 'react';

import { Divider, styled } from '@agentero/styles/jsx';

import { Text } from './Text';

type SectionComponents = {
	Title: FC<SectionTitleProps>;
};

const SectionRoot = styled('section', {
	base: {
		display: 'flex',
		flexDirection: 'column',
		gap: '24'
	}
});

type SectionTitleProps = {
	children: ReactNode;
};

const SectionTitleRoot = styled('hgroup', {
	base: {
		display: 'flex',
		flexDirection: 'column',
		gap: '4'
	}
});

const SectionTitle = ({ children }: SectionTitleProps) => (
	<SectionTitleRoot>
		<Text size="caption" uppercase as="h3" mb="4">
			{children}
		</Text>
		<Divider color="border" />
	</SectionTitleRoot>
);

export const Section: FC<PropsWithChildren> & SectionComponents = ({ children }) => (
	<SectionRoot>{children}</SectionRoot>
);

Section.Title = SectionTitle;
