import styled from '@emotion/styled';

import { Button } from '../Button';

/**
 * @deprecated
 */
export const TerciaryButton = styled(Button)`
	color: ${props => props.theme.colors.gray.base};
	svg {
		circle,
		path {
			fill: ${props => props.theme.colors.gray.base};
		}
	}

	@media (hover: hover) and (pointer: fine) {
		&:focus,
		&:hover {
			color: ${props => props.theme.colors.gray.base};
			background: ${props => props.theme.colors.gray.lighten95Alpha};

			svg {
				path:last-of-type {
					fill: ${props => props.theme.colors.gray.base};
				}
			}
		}
	}

	&:visited {
		color: ${props => props.theme.colors.gray.base};
	}

	&:active {
		color: ${props => props.theme.colors.gray.base};
		background: ${props => props.theme.colors.gray.lighten90Alpha};
	}
`;

/**
 * @deprecated
 */
export const TerciaryButtonLink = TerciaryButton.withComponent('a');
