import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from 'react';

import InputMask from 'react-input-mask';

import { Input, InputVariants } from '../fieldText/Input';

type InputDateProps = Omit<
	DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'css' | 'size'
> &
	InputVariants;

export const InputDate = forwardRef<HTMLInputElement, InputDateProps>(
	({ status, onChange, disabled, value, onBlur, ...props }, ref) => (
		<InputMask
			mask="99/99/9999"
			disabled={disabled}
			onChange={onChange}
			onBlur={onBlur}
			value={value}>
			<Input {...props} status={status} disabled={disabled} ref={ref} />
		</InputMask>
	)
);
