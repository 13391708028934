'use client';

import { PropsWithChildren } from 'react';

import { sva } from '@agentero/styles/css';
import { createStyleContext } from '@agentero/styles/tools';
import { RecipeVariantProps } from '@agentero/styles/types';

const cellSharedStyles = {
	verticalAlign: 'middle',
	paddingInline: '16',
	whiteSpace: 'nowrap',
	'&:first-of-type': {
		paddingInlineStart: '24'
	},
	'&:last-of-type': {
		paddingInlineEnd: '24'
	},
	'&:has([type="checkbox"])': {
		width: '0',
		paddingEnd: '0'
	}
};

const stickyRootSharedStyles = {
	overflow: 'unset',
	overflowX: 'unset'
};

const stickyHeaderSharedStyles = {
	'& > tr:first-of-type': {
		position: 'sticky',
		top: 0,
		zIndex: 1,
		background: 'bg',
		boxShadow: '0 0.03125rem 0 0 token(colors.border)'
	}
};

const stickyFooterSharedStyles = {
	'& > tr:last-of-type': {
		position: 'sticky',
		bottom: 0,
		zIndex: 1,
		background: 'bg',
		boxShadow: '0 -0.03125rem 0 0 token(colors.border)'
	}
};

export const tableStyles = sva({
	slots: ['root', 'table', 'head', 'body', 'row', 'header', 'cell'],
	base: {
		root: {
			'--border': '0.0625rem solid token(colors.border)',
			overflow: 'hidden',
			overflowX: 'auto'
		},
		table: {
			width: '100%'
		},
		head: {},
		body: {
			'& tr': {
				borderTop: 'var(--border)',
				transition: 'background-color 0.15s',
				_hover: {
					backgroundColor: 'neutral.95'
				}
			}
		},
		row: {
			transition: 'background-color 0.15s'
		},
		header: {
			height: '3rem',
			textAlign: 'left',
			textStyle: 'caption.base',
			color: 'neutral.50',
			...cellSharedStyles
		},
		cell: {
			textStyle: 'body.small',
			...cellSharedStyles
		}
	},
	variants: {
		size: {
			xs: {
				header: {
					height: '2rem'
				},
				cell: {
					paddingBlock: '8'
				}
			},
			sm: {
				header: {
					height: '2.5rem'
				},
				cell: {
					paddingBlock: '16'
				}
			},
			md: {
				header: {
					height: '3rem'
				},
				cell: {
					paddingBlock: '24'
				}
			}
		},
		enclosed: {
			true: {
				root: {
					borderRadius: 'md',
					border: 'var(--border)'
				}
			}
		},
		sticky: {
			header: {
				root: stickyRootSharedStyles,
				head: stickyHeaderSharedStyles
			},
			footer: {
				root: stickyRootSharedStyles,
				body: stickyFooterSharedStyles
			},
			headerAndFooter: {
				root: stickyRootSharedStyles,
				head: stickyHeaderSharedStyles,
				body: stickyFooterSharedStyles
			}
		}
	},
	defaultVariants: {
		enclosed: true,
		size: 'md'
	}
});

const { withProvider, withContext } = createStyleContext(tableStyles);

export type TableRootProps = RecipeVariantProps<typeof tableStyles>;

const TableRoot = ({ children, ...props }: PropsWithChildren<TableRootProps>) => {
	const Root = withProvider('div', 'root');
	const Table = withContext('table', 'table');

	return (
		<Root {...props}>
			<Table>{children}</Table>
		</Root>
	);
};

export const Table = {
	Root: TableRoot,
	Head: withContext('thead', 'head'),
	Body: withContext('tbody', 'body'),
	Row: withContext('tr', 'row'),
	Header: withContext('th', 'header'),
	Cell: withContext('td', 'cell')
};
