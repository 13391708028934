import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ModalBody, ModalFooter, ModalHeader } from '@agentero/components';
import { BwaTrainingResponse, questions } from '@agentero/models';
import { Carrier, getCarrierLabel } from '@agentero/models/shared';
import { Stack } from '@agentero/styles/jsx';
import { Button, Text } from '@agentero/ui';

import { bwaTrainingAssessmentCtaEvent } from 'packages/analytics/events/bwa-training-assessment';
import { useAgAnalytics } from 'packages/analytics/useAgAnalytics';

import { BwaTrainingQuestionForm } from './bwaTrainingAssesment/BwaTrainingQuestionForm';

type BwaTrainingAssesmentProps = {
	carrier: Carrier;
	onFinish: (data: BwaTrainingResponse) => void;
};

export const BwaTrainingAssesment = ({ carrier, onFinish }: BwaTrainingAssesmentProps) => {
	const { t } = useTranslation(['bindRequest']);

	const { trackEvent } = useAgAnalytics();

	const [state, setState] = useState<BwaTrainingResponse>();
	const [questionIndex, setQuestionIndex] = useState<number>(0);

	const formId = `assesment-form-${questionIndex}`;

	const onSubmitForm = (data: Partial<BwaTrainingResponse>) => {
		trackEvent(bwaTrainingAssessmentCtaEvent(carrier, questions[questionIndex].destination));
		setState({ ...data, ...state });
		if (questions.length > questionIndex + 1) {
			setQuestionIndex(questionIndex + 1);
		} else {
			onFinish({ ...data, ...state });
		}
	};

	return (
		<>
			<ModalHeader>
				<Stack>
					<Text size="title.section">
						{t('bwa-training.assesment.title', { carrier: getCarrierLabel(carrier) })}
					</Text>
					<Text size="body.small">
						{t('bwa-training.assesment.subtitle', {
							question: questionIndex + 1,
							total: questions.length
						})}
					</Text>
				</Stack>
			</ModalHeader>
			<ModalBody>
				<BwaTrainingQuestionForm
					key={questionIndex}
					question={questions[questionIndex]}
					formId={formId}
					onSubmit={onSubmitForm}
				/>
			</ModalBody>
			<ModalFooter>
				<Button type="submit" variant="primary" form={formId}>
					{questions.length > questionIndex + 1
						? t('bwa-training.assesment.nextQuestion')
						: t('bwa-training.assesment.lastQuestion')}
				</Button>
			</ModalFooter>
		</>
	);
};
