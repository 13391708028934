import styled from '@emotion/styled';

import { RoundIcon } from '@agentero/components';
import { Carrier, getCarrierLabel, getCarrierRaterConfig } from '@agentero/models/shared';

export type CarrierIconProps = {
	carrier: Carrier;
};

const CarrierIconContainer = styled.div`
	--size: ${({ theme }) => theme.sizes.getSize(5)};

	position: relative;
	width: var(--size);
	height: var(--size);
	pointer-events: auto;
`;

export const CarrierIcon = ({ carrier }: CarrierIconProps) => {
	const carrierRaterConfig = getCarrierRaterConfig(carrier);
	const CarrierIconFC = carrierRaterConfig.icon as React.FC;

	const carrierLabel = getCarrierLabel(carrier);

	return (
		<CarrierIconContainer>
			<RoundIcon
				icon={
					carrierRaterConfig.isRaterCarrier ? (
						<CarrierIconFC data-testid={`logo-${carrierLabel}`} css={{ padding: 8 }} />
					) : (
						carrier?.toString()[0].toUpperCase()
					)
				}
			/>
		</CarrierIconContainer>
	);
};
