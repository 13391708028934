import { GetCarrierDetailsRequest } from '@agentero/grpc-clients/grpc/carrier-fe';
import { CarrierDetails, parseCarrierDetails } from '@agentero/models';
import { Carrier, getCarrierProtoFromCarrier } from '@agentero/models/shared';
import { handleGrpcResponse } from '@agentero/service-errors';

import { carrierFrontendService } from 'packages/services/grpcClients';

import { AgenteroUserData } from '../../useAgQuery';

export type GetCarrierDetailArgs = {
	carrier: Carrier;
};

export const fetchCarrierDetails = ({
	token,
	userId,
	carrier
}: AgenteroUserData & GetCarrierDetailArgs) => {
	return new Promise<CarrierDetails>((resolve, reject) => {
		const request = new GetCarrierDetailsRequest();
		request.setCarrier(getCarrierProtoFromCarrier(carrier));

		carrierFrontendService.getCarrierDetails(
			request,
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}
				const carrierValue = parseCarrierDetails(response.toObject());
				if (carrierValue != undefined) {
					resolve(carrierValue);
				} else {
					reject(new Error('Carrier not found in backend response.'));
				}
			}
		);
	});
};
