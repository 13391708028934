import { PropsWithChildren, useRef } from 'react';

import { AriaButtonProps, useButton } from '@react-aria/button';

import { styled } from '@agentero/styles/jsx';

type ButtonAriaProps = AriaButtonProps<'button'> & {
	isPressed?: boolean;
};

const Button = styled('button', {
	base: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		width: '32px',
		height: '32px',
		minWidth: '32px',
		backgroundColor: 'white',
		color: 'neutral',
		borderRadius: 'none',
		outline: 'none',
		borderLeftStyle: 'solid',
		borderLeftWidth: '1px',
		borderLeftColor: 'neutral.80',
		_focus: {
			backgroundColor: 'neutral.90'
		},
		'&:hover': {
			backgroundColor: 'neutral.95'
		},
		'&:active': {
			backgroundColor: 'neutral.80'
		},
		'& svg': {
			width: '1.25rem',
			height: '1.25rem'
		}
	}
});

export const DatePickerButton = (props: PropsWithChildren<ButtonAriaProps>) => {
	const { children } = props;
	const ref = useRef(null);
	const { buttonProps } = useButton(props, ref);

	return (
		<Button {...buttonProps} ref={ref}>
			{children}
		</Button>
	);
};
