import { UseQueryOptions, useQuery } from '@tanstack/react-query';

export type GrpcRequest<TRequest extends {} = {}, TResponse extends {} = {}> = {
	request: (requestData: TRequest) => Promise<TResponse>;
	key: (requestData: TRequest) => (string | number | object)[];
};

export type UseAgenteroQueryOptions<TResponse> = Omit<
	UseQueryOptions<TResponse, unknown, TResponse, (string | number | object)[]>,
	'queryFn' | 'queryKey' | 'initialData'
>;

export const useAgenteroNoSuspenseQuery = <TRequest extends {} = {}, TResponse extends {} = {}>(
	queryConfig: GrpcRequest<TRequest, TResponse>,
	requestData: TRequest,
	reactQueryConfig?: UseAgenteroQueryOptions<TResponse>
) => {
	return useQuery(queryConfig.key(requestData), () => queryConfig.request(requestData), {
		...reactQueryConfig,
		suspense: false,
		useErrorBoundary: false
	});
};

export const useAgenteroQuery = <TRequest extends {} = {}, TResponse extends {} = {}>(
	queryConfig: GrpcRequest<TRequest, TResponse>,
	requestData: TRequest,
	reactQueryConfig?: Omit<
		UseQueryOptions<TResponse, unknown, TResponse, (string | number | object)[]>,
		'queryFn' | 'queryKey' | 'initialData'
	>
) => {
	return useQuery(queryConfig.key(requestData), () => queryConfig.request(requestData), {
		...reactQueryConfig,
		suspense: true,
		useErrorBoundary: true
	});
};
