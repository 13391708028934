import { UseQueryOptions } from '@tanstack/react-query';

import { CarrierItem } from '@agentero/models';

import { GrpcPortalRequest, useAgQuery } from '../../useAgQuery';
import { FetchCarriersArgs, fetchCarrierList } from './fetchCarrierList';

export const carrierList = 'carrier-list';

const carrierListQueryConfiguration: GrpcPortalRequest<FetchCarriersArgs, CarrierItem[]> = {
	request: fetchCarrierList,
	key: ({ userId, search }) => [carrierList, userId, search]
};

export const useCarrierListQuery = (
	search: FetchCarriersArgs,
	config?: UseQueryOptions<CarrierItem[]>
) => useAgQuery(carrierListQueryConfiguration, search, config);
