'use client';

import { ComponentProps, ElementRef, ReactNode, forwardRef } from 'react';

import * as AvatarPrimitive from '@radix-ui/react-avatar';

import { cva } from '@agentero/styles/css';
import { styled } from '@agentero/styles/jsx';
import { ColorToken, Token, token } from '@agentero/styles/tokens';
import { StyledVariantProps } from '@agentero/styles/types';

export const avatarRecipe = cva({
	base: {
		alignItems: 'center',
		justifyContent: 'center',
		verticalAlign: 'middle',
		overflow: 'hidden',
		userSelect: 'none',
		boxSizing: 'border-box',
		display: 'flex',
		flexShrink: 0,
		position: 'relative',
		fontFamily: 'inherit',
		margin: '0',
		outline: 'none',
		padding: '0',
		fontWeight: '600',
		color: 'text',
		backgroundColor: 'bg',
		borderWidth: 1,
		borderStyle: 'solid',
		borderColor: 'border',
		height: 'fit-content',
		width: 'fit-content'
	},
	variants: {
		size: {
			xs: {
				width: '1.5rem',
				height: '1.5rem'
			},
			sm: {
				width: '2rem',
				height: '2rem'
			},
			md: {
				width: '2.5rem',
				height: '2.5rem'
			},
			lg: {
				width: '3rem',
				height: '3rem'
			},
			xl: {
				width: '4rem',
				height: '4rem'
			},
			'2xl': {
				width: '5rem',
				height: '5rem'
			},
			'3xl': {
				width: '6rem',
				height: '6rem'
			},
			'4xl': {
				width: '8rem',
				height: '8rem'
			}
		},
		variant: {
			circle: {
				borderRadius: '50%'
			},
			pillow: {
				borderRadius: '30%',
				maskImage:
					'url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMjAwJyBoZWlnaHQ9JzIwMCcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJz48cGF0aCBkPSdNMTAwIDBDMjAgMCAwIDIwIDAgMTAwczIwIDEwMCAxMDAgMTAwIDEwMC0yMCAxMDAtMTAwUzE4MCAwIDEwMCAwWicvPjwvc3ZnPg==)',
				maskSize: 'contain'
			}
		}
	},
	defaultVariants: {
		size: 'md',
		variant: 'circle'
	}
});

const AvatarRoot = styled(AvatarPrimitive.Root, avatarRecipe);

const AvatarImage = styled(AvatarPrimitive.Image, {
	base: {
		display: 'flex',
		objectFit: 'cover',
		boxSizing: 'border-box',
		height: '100%',
		verticalAlign: 'middle',
		width: '100%'
	}
});

const AvatarFallback = styled(AvatarPrimitive.Fallback, {
	base: {
		textTransform: 'uppercase',
		'& svg': {
			width: '1.5em',
			height: '1.5em',
			fill: 'neutral'
		}
	},
	variants: {
		size: {
			xs: {
				fontSize: 12,
				lineHeight: 18
			},
			sm: {
				fontSize: 14
			},
			md: {
				fontSize: 16
			},
			lg: {
				fontSize: 18
			},
			xl: {
				fontSize: 22
			},
			'2xl': {
				fontSize: 26
			},
			'3xl': {
				fontSize: 32
			},
			'4xl': {
				fontSize: 48
			}
		}
	},
	defaultVariants: {
		size: 'md'
	}
});

type AvatarVariants = Omit<StyledVariantProps<typeof AvatarRoot>, 'css'>;
type AvatarPrimitiveProps = Omit<ComponentProps<typeof AvatarPrimitive.Root>, 'css'>;

type AvatarProps = AvatarPrimitiveProps &
	AvatarVariants & {
		alt?: string;
		src?: string;
		fallback?: ReactNode;
		colorize?: string;
	};

export const Avatar = forwardRef<ElementRef<typeof AvatarRoot>, AvatarProps>(
	({ alt, src, fallback, size, colorize, ...props }, forwardedRef) => {
		const style = colorize ? getStyleFromColorize(colorize) : undefined;

		return (
			<AvatarRoot {...props} ref={forwardedRef} size={size} style={style}>
				<AvatarImage alt={alt} src={src} />
				<AvatarFallback size={size}>{fallback}</AvatarFallback>
			</AvatarRoot>
		);
	}
);

const getColorTokenByName = (name: string, brightness = 20): ColorToken => {
	const hash = name.split('').reduce((acc, char, index) => {
		return acc + (char.charCodeAt(0) - 97 + 1) * Math.pow(26, index);
	}, 0);

	const colors = [
		'neutral',
		'brand',
		'blue',
		'purple',
		'pink',
		'orange',
		'red',
		'warning',
		'success'
	];
	const index = Math.abs(hash) % colors.length;

	return `${colors[index]}.${brightness}` as ColorToken;
};

const getStyleFromColorize = (colorize: string) => {
	const darkColorToken = getColorTokenByName(colorize);
	const lightColorToken = getColorTokenByName(colorize, 90);

	return {
		color: token(`colors.${darkColorToken}` as Token),
		backgroundColor: token(`colors.${lightColorToken}` as Token),
		borderColor: token(`colors.${lightColorToken}` as Token)
	};
};
