import { GetCarrierRaterConfigurationsResponse } from '@agentero/grpc-clients/grpc/agency-fe';
import { ApprovalStatus } from '@agentero/grpc-clients/grpc/shared/carrier';

import { Carrier, getCarrierFromProto } from '../shared';

export enum BwaApprovalStatus {
	NotRequested = 'NotRequested',
	Requested = 'Requested',
	Approved = 'Approved',
	Rejected = 'Rejected'
}

const map = {
	[ApprovalStatus.APPROVAL_STATUS_APPROVED]: BwaApprovalStatus.Approved,
	[ApprovalStatus.APPROVAL_STATUS_NOT_APPROVED]: BwaApprovalStatus.NotRequested,
	[ApprovalStatus.APPROVAL_STATUS_REQUESTED]: BwaApprovalStatus.Requested,
	[ApprovalStatus.APPROVAL_STATUS_UNSPECIFIED]: BwaApprovalStatus.NotRequested
};

export const parseBwaApprovalStatus = (status: ApprovalStatus): BwaApprovalStatus => map[status];

export type CarrierBwaApproval = {
	carrier: Carrier;
	enabled: boolean;
	needsApproval: boolean;
	approvalStatus?: BwaApprovalStatus;
};

export const parseCarriersApproval = (
	response: GetCarrierRaterConfigurationsResponse.AsObject
): CarrierBwaApproval[] => {
	return response.carriersList.map(({ carrier, disabled, bwaApprovalStatus }) => ({
		carrier: getCarrierFromProto(carrier),
		enabled: !disabled,
		needsApproval: getCarrierFromProto(carrier) === Carrier.Bamboo,
		approvalStatus: bwaApprovalStatus
			? parseBwaApprovalStatus(bwaApprovalStatus)
			: BwaApprovalStatus.NotRequested
	}));
};
