import { PropsWithChildren } from 'react';

import {
	IconCheckCircle,
	IconClose,
	IconErrorOutline,
	IconInfoOutline,
	IconStar,
	IconWarning
} from '@agentero/icons';
import { HStack, Stack, styled } from '@agentero/styles/jsx';
import { StyledVariantProps } from '@agentero/styles/types';

import { Button } from './Button';
import { Text } from './Text';

const AlertRoot = styled('div', {
	base: {
		display: 'flex',
		alignItems: 'start',
		color: 'text',
		textStyle: 'caption.base',
		borderRadius: 'sm',
		position: 'relative'
	},
	variants: {
		color: {
			neutral: {
				backgroundColor: 'neutral.90'
			},
			success: {
				backgroundColor: 'success.95'
			},
			danger: {
				backgroundColor: 'danger.95'
			},
			warning: {
				backgroundColor: 'warning.95'
			},
			purple: {
				backgroundColor: 'purple.95'
			},
			info: {
				backgroundColor: 'blue.90'
			}
		},
		size: {
			sm: {
				gap: '8',
				paddingBlock: '8',
				paddingInline: '12',
				'& svg': {
					width: '16',
					height: '16'
				}
			},
			md: {
				gap: '16',
				paddingBlock: '12',
				paddingInline: '18',
				'& svg': {
					width: '20',
					height: '20'
				}
			},
			lg: {
				gap: '16',
				paddingBlock: '24',
				paddingInline: '40',
				'& svg': {
					width: '20',
					height: '20'
				}
			}
		}
	},
	defaultVariants: {
		color: 'neutral',
		size: 'md'
	}
});

const AlertTitle = ({ children }: PropsWithChildren) => {
	return <Text size="body.small">{children}</Text>;
};

const AlertParagraph = ({ children }: PropsWithChildren) => {
	return <Text size="caption">{children}</Text>;
};

const AlertPictograms = styled('div', {
	base: {
		'& svg': {
			height: '64',
			width: '64'
		}
	}
});

type AlertProps = {
	onDismiss?: () => void;
	hasIcon?: boolean;
} & StyledVariantProps<typeof AlertRoot>;

export type AlertColorType = NonNullable<StyledVariantProps<typeof AlertRoot>>['color'];

const AlertContent = ({ children }: PropsWithChildren) => (
	<Stack gap="8" height="100%" alignSelf="start">
		{children}
	</Stack>
);

const AlertActions = ({ children }: PropsWithChildren) => (
	<Stack
		gap="8"
		marginLeft="auto"
		justifyContent="center"
		alignItems="center"
		mixBlendMode="multiply">
		{children}
	</Stack>
);

const AlertDismiss = styled('div', {
	base: {
		position: 'absolute',
		top: '4',
		right: '4',
		mixBlendMode: 'multiply'
	}
});

export const Alert = ({
	children,
	onDismiss,
	hasIcon = true,
	...props
}: PropsWithChildren<AlertProps>) => {
	const Icon = iconMapping[props.color ?? 'neutral'];

	return (
		<AlertRoot role="alert" aria-labelledby="alertText" {...props}>
			{onDismiss && (
				<AlertDismiss>
					<Button variant="tertiary" onClick={onDismiss}>
						<IconClose />
					</Button>
				</AlertDismiss>
			)}
			{hasIcon && <Icon />}
			<HStack id="alertText" alignItems="center" width="100%" gap="40">
				{children}
			</HStack>
		</AlertRoot>
	);
};

Alert.Title = AlertTitle;
Alert.Paragraph = AlertParagraph;
Alert.Content = AlertContent;
Alert.Actions = AlertActions;
Alert.Pictograms = AlertPictograms;

const iconMapping: { [key in Exclude<AlertProps['color'], undefined>]: () => JSX.Element } = {
	neutral: IconInfoOutline,
	success: IconCheckCircle,
	danger: IconErrorOutline,
	info: IconInfoOutline,
	warning: IconWarning,
	purple: IconStar
};
