import { Carrier } from '@agentero/models/shared';

import { AgEvent } from '../useAgAnalytics';

export const bwaTrainingAssessmentCtaEvent = (carrier: Carrier, question: string): AgEvent => {
	return {
		eventName: 'bwa-training-assessment',
		properties: {
			carrier,
			question
		}
	};
};
