import { forwardRef, useState } from 'react';

import styled from '@emotion/styled';

import { IconEdit, IconOpenInNew, IconVisibility } from '@agentero/icons';

import { IconText } from '../IconText';
import { TerciaryButton } from '../button/TerciaryButton';
import { InputProps } from './Input';
import { TextArea } from './TextArea';
import { MarkdownStyles } from './markdownInput/MarkdownStyles';
import { MarkdownView } from './markdownInput/MarkdownView';

const InputContainer = styled.div<{ markdownView: boolean }>`
	display: flex;
	flex-direction: column;
	position: relative;
	border: solid 1px ${({ theme }) => theme.colors.gray.lighten80};
	padding: ${({ theme: { sizes } }) => sizes.getSize(1)};
	gap: ${({ theme: { sizes } }) => sizes.getSize(1)};
	min-width: 300px;
	border-radius: 2px;

	textarea {
		height: 200px;

		${({ markdownView }) => (markdownView ? 'visibility: hidden;' : '')}
	}
`;

const ButtonHeader = styled.header`
	display: flex;
	justify-content: space-between;
`;

const ContentContainer = styled.div`
	position: relative;
`;

const MarkdownContentContainer = styled.div`
	position: absolute;
	top: ${({ theme: { sizes } }) => sizes.getSize(1)};
	left: ${({ theme: { sizes } }) => sizes.getSize(1)};
`;

type MarkdownInputType = InputProps &
	React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

export const MarkdownInput = forwardRef<HTMLTextAreaElement, MarkdownInputType>(
	({ value, ...props }, ref) => {
		const [markdownView, setMarkdownView] = useState(false);

		return (
			<>
				<MarkdownStyles />
				<InputContainer markdownView={markdownView}>
					<ButtonHeader>
						{!markdownView ? (
							<TerciaryButton type="button" onClick={() => setMarkdownView(true)}>
								<IconText label="Preview">
									<IconVisibility />
								</IconText>
							</TerciaryButton>
						) : (
							<TerciaryButton type="button" onClick={() => setMarkdownView(false)}>
								<IconText label="Edit">
									<IconEdit />
								</IconText>
							</TerciaryButton>
						)}
						<TerciaryButton
							type="button"
							onClick={() =>
								window.open(
									'https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax',
									'_blank',
									'noreferrer'
								)
							}>
							<IconText label="Markdown Rules">
								<IconOpenInNew />
							</IconText>
						</TerciaryButton>
					</ButtonHeader>
					<ContentContainer>
						<TextArea ref={ref} value={value} disabled={markdownView} {...props} />
						{markdownView && (
							<MarkdownContentContainer>
								<MarkdownView value={value as string} />
							</MarkdownContentContainer>
						)}
					</ContentContainer>
				</InputContainer>
			</>
		);
	}
);
