import { useTranslation } from 'react-i18next';

import { ModalBody, ModalHeader } from '@agentero/components';
import { PictogramClipboardCheck } from '@agentero/icons';
import { css } from '@agentero/styles/css';
import { Stack } from '@agentero/styles/jsx';
import { Button, Text } from '@agentero/ui';

import { carrierDetailsQueryInvalidate } from 'packages/services/fetch/carrier-fe/carrier-details/useCarrierDetailsQuery';

type BwaTrainingSuccessProps = {
	onDone: () => void;
};

export const BwaTrainingSuccess = ({ onDone }: BwaTrainingSuccessProps) => {
	const { t } = useTranslation('bindRequest');

	const onClick = () => {
		carrierDetailsQueryInvalidate();
		onDone();
	};

	return (
		<>
			<ModalHeader></ModalHeader>
			<ModalBody>
				<Stack>
					<Stack alignItems="center" justifyContent="center">
						<PictogramClipboardCheck className={css({ height: 108, width: 180 })} />
					</Stack>
					<Text size="title.section" align="center">
						{t('bwa-training.assesment.success.title')}
					</Text>
					<Text size="body.small" align="center">
						{t('bwa-training.assesment.success.body')}
					</Text>
					<Stack width="100%" paddingBottom="32">
						<Button variant="primary" onClick={onClick}>
							{t('bwa-training.assesment.success.button')}
						</Button>
						<Text color="weak" size="caption">
							{t('bwa-training.assesment.success.caption')}{' '}
							<a href="mailto:service@agentero.com">service@agentero.com</a>
						</Text>
					</Stack>
				</Stack>
			</ModalBody>
		</>
	);
};
