import {
	Carrier as AppointmentCarrier,
	ListSuperAdminCarriersResponse
} from '@agentero/grpc-clients/grpc/carrier-fe';
import { getDateFromGrpc, moveItemToFirst } from '@agentero/utils';

import { InsuranceType, parseInsuranceTypeFromProto } from './opportunity/InsuranceType';
import { Carrier, getCarrierFromProto } from './shared/Carrier';
import { ComparativeRater, parseComparativeRater } from './shared/ComparativeRater';
import { State, parseStateFromString } from './shared/State';

export type AdminCarrierAppointment = {
	carrier: Carrier;
	lobsList: InsuranceType[];
	states: State[];
	url?: string;
	launchDate?: Date;
	transferUrl?: string;
	activateEligibility: boolean;
	eligibilityCriteria: { [key: string]: boolean };
	isAppointable: boolean;
	hasBWAApprovalRequired: boolean;
	appetiteRaterContent: string;
	isHiddenOnLanding: boolean;
	briefCarrierContent: string;
	highlightsContent: string;
	comparativeRaterAvailabilitiesList: ComparativeRater[];
};

export const parseAdminCarrierAppointment = (
	carrier: AppointmentCarrier.AsObject
): AdminCarrierAppointment => ({
	carrier: getCarrierFromProto(carrier.carrierId),
	lobsList: carrier.lobsList.map(parseInsuranceTypeFromProto),
	states: (carrier.statesList as State[]).map(parseStateFromString),
	url: carrier.url.trim(),
	launchDate: getDateFromGrpc(carrier.launchDate),
	transferUrl: carrier.transferUrl.trim(),
	activateEligibility: !getVisibilityCriteria(
		carrier.visibilityCriteriaMap,
		'IsHiddenOnAppointments'
	),
	hasBWAApprovalRequired: getVisibilityCriteria(
		carrier.visibilityCriteriaMap,
		'HasBWAApprovalRequired'
	),
	eligibilityCriteria: parseEligibilityCriteria(carrier.eligibilityCriteriaMap),
	isAppointable: carrier.isAgenteroCarrier,
	appetiteRaterContent: carrier.appetiteContent,
	isHiddenOnLanding: getVisibilityCriteria(carrier.visibilityCriteriaMap, 'IsHiddenOnLanding'),
	briefCarrierContent: carrier.brief,
	highlightsContent: carrier.highlights,
	comparativeRaterAvailabilitiesList: carrier.comparativeRaterAvailabilitiesList
		.map(
			comparativeRater =>
				comparativeRater.isAvailable && parseComparativeRater(comparativeRater.raterName)
		)
		.filter(Boolean) as ComparativeRater[]
});

const getVisibilityCriteria = (visibilityCriteriaMap: [string, boolean][], field: string) => {
	const criteria = visibilityCriteriaMap?.find(([key]) => key === field);
	return criteria ? criteria[1] : false;
};

export const parseAdminCarrierAppointments = (
	carriers: ListSuperAdminCarriersResponse.AsObject
): AdminCarrierAppointment[] =>
	carriers.carriersList.map(carrier => ({
		carrier: getCarrierFromProto(carrier.carrierId),
		lobsList: moveItemToFirst(
			InsuranceType.ExcessAndSurplusCommercialCasualty,
			carrier.lobsList.map(parseInsuranceTypeFromProto)
		),
		states: (carrier.statesList as State[]).map(parseStateFromString),
		url: carrier.url,
		launchDate: getDateFromGrpc(carrier.launchDate),
		transferUrl: carrier.transferUrl,
		activateEligibility: !getVisibilityCriteria(
			carrier.visibilityCriteriaMap,
			'IsHiddenOnAppointments'
		),
		hasBWAApprovalRequired: getVisibilityCriteria(
			carrier.visibilityCriteriaMap,
			'HasBWAApprovalRequired'
		),
		eligibilityCriteria: parseEligibilityCriteria(carrier.eligibilityCriteriaMap),
		isAppointable: carrier.isAgenteroCarrier,
		appetiteRaterContent: carrier.appetiteContent,
		isHiddenOnLanding: getVisibilityCriteria(carrier.visibilityCriteriaMap, 'IsHiddenOnLanding'),
		// TODO: Update from backend
		briefCarrierContent: carrier.brief,
		highlightsContent: carrier.highlights,
		comparativeRaterAvailabilitiesList: carrier.comparativeRaterAvailabilitiesList
			.map(
				comparativeRater =>
					comparativeRater.isAvailable && parseComparativeRater(comparativeRater.raterName)
			)
			.filter(Boolean) as ComparativeRater[]
	}));

const parseEligibilityCriteria = (
	questions: [string, boolean][]
): {
	[key: string]: boolean;
} =>
	questions.length > 0 ? questions.reduce((acc, item) => ({ ...acc, [item[0]]: item[1] }), {}) : {};
