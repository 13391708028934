import React from 'react';

import { Global, css } from '@emotion/react';

const MarkdownStyles = () => {
	return (
		<Global
			styles={css`
				@media (prefers-color-scheme: light) {
					.markdown-body {
						--base-text-weight-semibold: 500;
					}
				}

				.markdown-body {
					margin: 0;
					color: var(--color-fg-default);
					word-wrap: break-word;

					strong {
						font-weight: var(--base-text-weight-semibold, 500);
					}

					h3 {
						margin-bottom: 8px;
					}

					ul {
						list-style-type: disc;
						margin-top: 0px;
						margin-bottom: 16px;
						padding-left: 2em;

						li > p {
							margin-top: 16px;
						}

						li + li {
							margin-top: 0.25em;
						}
					}
				}
			`}
		/>
	);
};

export { MarkdownStyles };
