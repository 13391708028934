import React, { PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import ReactDOM from 'react-dom';

import { IconKebap } from '@agentero/icons';

import { TerciaryButton } from '../button/TerciaryButton';
import { MenuContainer } from './shared/MenuContainer';
import { MenuPanel } from './shared/MenuPanel';
import { useMenu } from './shared/useMenu';

type KebapCoords = {
	left: number;
	top: number;
	width: number;
	height: number;
};

type KebabMenuPanelContainerProps = {
	coords: KebapCoords;
};

const KebabMenuPanelContainer = styled.div<KebabMenuPanelContainerProps>`
	position: absolute;
	pointer-events: none;
	top: ${({ coords }) => coords.top}px;
	left: ${({ coords }) => coords.left}px;
	width: ${({ coords }) => coords.width}px;
	height: ${({ coords }) => coords.height}px;
`;

export const KebabMenu = ({ children, ...props }: PropsWithChildren) => {
	const { isOpen, closePanel, onMenuClick } = useMenu();
	const [coords, setCoords] = useState({ left: 0, top: 0, width: 0, height: 0 });
	const kebap = useRef<HTMLDivElement>(null);

	useEffect(() => {
		window.addEventListener('wheel', () => closePanel());
		return () => {
			window.removeEventListener('wheel', () => closePanel());
		};
	}, [coords]);

	const onKebapMenuClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
		if (kebap && kebap.current) {
			const { left, top, width, height } = kebap.current.getBoundingClientRect() as DOMRect;
			setCoords({
				left,
				top: top + window.scrollY,
				width,
				height
			});
			onMenuClick(event);
		}
	};

	return (
		<MenuContainer ref={kebap} {...props}>
			<TerciaryButton onClick={onKebapMenuClick}>
				<IconKebap />
			</TerciaryButton>
			{isOpen &&
				(ReactDOM.createPortal(
					<KebabMenuPanelContainer coords={coords}>
						<MenuPanel closePanel={closePanel}>{children}</MenuPanel>
					</KebabMenuPanelContainer>,
					document.body
				) as ReactNode)}
		</MenuContainer>
	);
};
