import { useTranslation } from 'react-i18next';

import { ModalBody, ModalFooter, ModalHeader } from '@agentero/components';
import { IconHelp } from '@agentero/icons';
import { Carrier, getCarrierLabel } from '@agentero/models/shared';
import { HStack, styled } from '@agentero/styles/jsx';
import { Button, Text, Tooltip, YoutubeVideoContainer } from '@agentero/ui';

import { BindWithAgenteroCarrier } from '../BindWithAgenteroCarrier';

type BwaTrainingVideoStepProps = {
	carrier: Carrier;
	onDismiss: () => void;
	onConfirm: () => void;
};

const IconHelpCustom = styled(IconHelp, {
	base: {
		width: '1.25rem',
		height: '1.25rem'
	}
});

export const BwaTrainingVideoStep = ({
	carrier,
	onDismiss,
	onConfirm
}: BwaTrainingVideoStepProps) => {
	const { t } = useTranslation(['bindRequest', 'shared']);

	return (
		<>
			<YoutubeVideoContainer youTubeId="JB6Fk-79Yko" title="Bwa Training Video" />
			<ModalHeader>
				<HStack>
					<BindWithAgenteroCarrier carrier={carrier} />
					<Text>{t('bwa-training.modal.title', { carrier: getCarrierLabel(carrier) })}</Text>
				</HStack>
			</ModalHeader>
			<ModalBody>
				<Text>{t('bwa-training.modal.body', { carrier: getCarrierLabel(carrier) })}</Text>
			</ModalBody>
			<ModalFooter
				leftSide={
					<>
						<HStack marginRight="auto">
							<Text size="caption">{t('bwa-training.modal.alert')}</Text>
						</HStack>
						<Tooltip content={<div>{t('bwa-training.modal.alert-tooltip')}</div>}>
							<IconHelpCustom />
						</Tooltip>
					</>
				}>
				<Button variant="secondary" onClick={onDismiss}>
					{t('shared:cancel')}
				</Button>
				<Button onClick={onConfirm}>{t('shared:continue')}</Button>
			</ModalFooter>
		</>
	);
};
