import {
	GetCarrierDetailsResponse,
	ListCarriersResponse
} from '@agentero/grpc-clients/grpc/carrier-fe';
import { ApprovalStatus } from '@agentero/grpc-clients/grpc/shared/carrier';

import { BwaApprovalStatus } from '../agency';
import { parseBwaApprovalStatus } from '../agency/CarrierBwaApproval';
import { InsuranceType, parseInsuranceTypeFromProto } from '../opportunity';
import { Carrier, getCarrierFromProto } from '../shared';
import {
	AppointmentRequestStatus,
	parseAppointmentRequestStatus
} from './AppointmentRequestStatus';

export type LobQuotableStates = {
	lob: InsuranceType;
	statesList: string[];
};

type DisabledStatesType = 'bwa' | 'appointment';

export type DisabledStates = {
	type: DisabledStatesType;
	statesList: string[];
};

export type CarrierDetails = CarrierItem & {
	states: string[];
	quotableLobsStates: LobQuotableStates[];
	disabledStates: DisabledStates[];
	brief: string;
	highlights: string;
	hasAgentManagedCredentials: boolean;
};

export const parseCarrierDetails = (
	carrierResponse: GetCarrierDetailsResponse.AsObject
): CarrierDetails | undefined =>
	carrierResponse.carrier
		? {
				carrier: getCarrierFromProto(carrierResponse.carrier?.carrier),
				isEligible: carrierResponse.carrier.isEligible,
				appointmentRequestStatus: parseAppointmentRequestStatus(carrierResponse.carrier.status),
				linesOfBusiness: carrierResponse.carrier.lobsList.map(lob =>
					parseInsuranceTypeFromProto(lob)
				),
				isQuotable: carrierResponse.carrier.isQuotable,
				isBindingWithAgentero: carrierResponse.carrier.isBindingWithAgentero,
				hasBwaApprovalRequired:
					carrierResponse.carrier.bwaApprovalStatus !== ApprovalStatus.APPROVAL_STATUS_UNSPECIFIED,
				isTransferable: !!carrierResponse.carrier.transferUrl,
				transferUrl: carrierResponse.carrier.transferUrl,
				brief: carrierResponse.carrier.brief,
				states: carrierResponse.carrier.statesList,
				quotableLobsStates: carrierResponse.carrier.quotableLobsList.map(lobValue => ({
					lob: parseInsuranceTypeFromProto(lobValue.lob),
					statesList: lobValue.statesList
				})),
				disabledStates: [
					{
						type: 'bwa',
						statesList: carrierResponse.carrier.disabledBwaStatesList
					},
					{
						type: 'appointment',
						statesList: carrierResponse.carrier.disabledOwnCodeStatesList
					}
				],
				highlights: carrierResponse.carrier.highlights,
				hasAppointmentForm: carrierResponse.carrier.hasAppointmentForm,
				hasAgentManagedCredentials: carrierResponse.carrier.missingCredentials,
				isInstantAppointment: carrierResponse.carrier.isInstantAppointment,
				bwaApprovalStatus: parseBwaApprovalStatus(carrierResponse.carrier.bwaApprovalStatus)
		  }
		: undefined;

export type CarrierItem = {
	carrier: Carrier;
	isEligible: boolean;
	appointmentRequestStatus?: AppointmentRequestStatus;
	linesOfBusiness: InsuranceType[];
	isQuotable: boolean;
	isBindingWithAgentero: boolean;
	hasBwaApprovalRequired: boolean;
	isTransferable: boolean;
	transferUrl?: string;
	hasAppointmentForm: boolean;
	highlights: string;
	brief?: string;
	isInstantAppointment: boolean;
	bwaApprovalStatus: BwaApprovalStatus;
};

export const parseCarriersList = (response: ListCarriersResponse.AsObject): CarrierItem[] => {
	return response.carriersList.map(carrierResponse => ({
		carrier: getCarrierFromProto(carrierResponse.carrier),
		isEligible: carrierResponse.isEligible,
		appointmentRequestStatus: parseAppointmentRequestStatus(carrierResponse.status),
		linesOfBusiness: carrierResponse.lobsList.map(lob => parseInsuranceTypeFromProto(lob)),
		isQuotable: carrierResponse.isQuotable,
		isBindingWithAgentero: carrierResponse.isBindingWithAgentero,
		hasBwaApprovalRequired:
			carrierResponse.bwaApprovalStatus !== ApprovalStatus.APPROVAL_STATUS_UNSPECIFIED,
		isTransferable: !!carrierResponse.transferUrl,
		transferUrl: carrierResponse.transferUrl,
		hasAppointmentForm: carrierResponse.hasAppointmentForm,
		highlights: carrierResponse.highlights,
		brief: carrierResponse.brief,
		isInstantAppointment: carrierResponse.isInstantAppointment,
		bwaApprovalStatus: parseBwaApprovalStatus(carrierResponse.bwaApprovalStatus)
	}));
};
