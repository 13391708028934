import { PropsWithChildren, useState } from 'react';

import { Global, css } from '@emotion/react';
import styled from '@emotion/styled';

import { theme } from '@agentero/styles/emotion';

import { SidebarCloseButton } from './sidebar/SidebarCloseButton';

export type SidebarProps = PropsWithChildren<{
	isOpen: boolean;
	onClose: () => void;
	fixed?: boolean;
	withOverlay?: boolean;
}>;

const SidebarContainer = styled.div<{
	zIndex: number;
	fixed: boolean;
	withOverlay: boolean;
}>`
	display: flex;
	flex-direction: column;
	position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
	top: 0;
	bottom: 0;
	right: 0;
	width: 100%;
	overflow: hidden;
	background: ${({ theme }) => theme.colors.white};
	${({ theme, withOverlay }) =>
		!withOverlay && `border-left: 1px solid ${theme.colors.gray.lighten90}`};
	z-index: ${({ zIndex }) => zIndex};

	${props => props.theme.mediaqueries.L} {
		max-width: 100%;
		width: ${({ theme }) => theme.sizes.sidebar};
	}
`;

const styles = css`
	main,
	[role='banner'] {
		@media (min-width: 1024px) {
			width: calc(100% - ${theme.sizes.sidebar});
		}
	}
`;

const Overlay = styled.div<{ fixed: boolean }>`
	position: ${({ fixed }) => (fixed ? 'fixed' : 'absolute')};
	top: 0;
	bottom: 0;
	right: 0;
	width: 100%;

	background-color: rgba(0, 0, 0, 0.3);
`;

export const Sidebar = ({ isOpen, ...props }: SidebarProps) =>
	isOpen ? <SidebarStackOrder {...props} /> : null;

const SidebarStackOrder = ({
	onClose,
	fixed = true,
	withOverlay = false,
	children
}: Omit<SidebarProps, 'isOpen'>) => {
	const [zIndex] = useState(() => {
		const now = Date.now();
		return now % Number(now.toString().substr(0, 12));
	});

	return (
		<>
			{fixed && <Global styles={styles} />}
			{withOverlay && <Overlay fixed={fixed} onClick={onClose} />}
			<SidebarContainer zIndex={zIndex} withOverlay={withOverlay} fixed={fixed}>
				<SidebarCloseButton onClose={onClose} />
				{children}
			</SidebarContainer>
		</>
	);
};
