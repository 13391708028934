import { Children, ReactNode, cloneElement, isValidElement } from 'react';

export const renderChildrenByName = <T extends {}>(
	children: ReactNode,
	displayName: string,
	props?: Partial<T>
) => {
	return Children.map(
		children,
		child =>
			isValidElement(child) &&
			typeof child.type === 'function' &&
			'displayName' in child.type &&
			child.type.displayName === displayName &&
			cloneElement(child, props)
	);
};
