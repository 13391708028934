import { useQuery, useQueryClient } from '@tanstack/react-query';

import { GetSessionRequest } from '@agentero/grpc-clients/grpc/portal-fe';
import { Agent, parseAgent } from '@agentero/models';
import { AgentSession, CurrentExpertResponse, parseAgentSession } from '@agentero/models/agent';
import {
	RequestError,
	handleFetchCatchError,
	handleFetchResponse,
	handleGrpcResponse
} from '@agentero/service-errors';

import { useAgentUniqueIdentifier } from 'packages/contexts/AgentUniqueIdentifierContext';
import { queryClient } from 'packages/services/QueryCache';
import { portalFrontendClient } from 'packages/services/grpcClients';

export const currentExpertResourceKey = 'current_expert';
const authUrl =
	process.env.NEXT_PUBLIC_ENVIRONMENT === 'local'
		? `${process.env.NEXT_PUBLIC_LOCAL_API_URL}/current_expert`
		: `${process.env.NEXT_PUBLIC_API_URL}/current_expert`;

export const fetchAgentSession = (token: string, agentId: string) =>
	new Promise<AgentSession>((resolve, reject) => {
		const request = new GetSessionRequest();

		portalFrontendClient.getSession(
			request,
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, agentId);
					return;
				}

				const agentSession = parseAgentSession(response.toObject());
				resolve(agentSession);
			}
		);
	});

export const fetchCurrentAgent = (init?: RequestInit): Promise<Agent> =>
	new Promise(async (resolve, reject) => {
		try {
			const response = await fetch(authUrl, init);
			const { data }: { data: CurrentExpertResponse } = await handleFetchResponse(
				response,
				reject,
				'none'
			);

			if (data.attributes.agencyAdmin !== undefined) {
				const response = await fetchAgentSession(data.attributes.authenticationToken, data.id);
				resolve(parseAgent(data, response));
			}
			return resolve(parseAgent(data));
		} catch (error) {
			if (error instanceof RequestError) {
				reject(error);
			} else {
				handleFetchCatchError(error, reject, 'none');
			}
		}
	});

export const useAgentResource = () => {
	const { agentUniqueIdentifier } = useAgentUniqueIdentifier();
	return useQuery([currentExpertResourceKey, agentUniqueIdentifier], () => fetchCurrentAgent(), {
		cacheTime: Infinity,
		refetchOnMount: false,
		refetchOnWindowFocus: false,
		suspense: true,
		staleTime: Infinity,
		retry: false
	});
};

export const useInvalidateAgentResource = () => {
	const queryClient = useQueryClient();

	return () => queryClient.invalidateQueries([currentExpertResourceKey]);
};

export const getAgentData = () =>
	queryClient.getQueryData<Agent>(undefined, {
		exact: false,
		queryKey: [currentExpertResourceKey]
	});

export const prefetchAgent = (init: RequestInit, agentUniqueIdentifier: string) => {
	return queryClient.fetchQuery(
		[currentExpertResourceKey, agentUniqueIdentifier],
		() => fetchCurrentAgent(init),
		{
			staleTime: Infinity,
			retry: 1
		}
	);
};
