import { Struct } from 'google-protobuf/google/protobuf/struct_pb';

import { UpdateAgentUIStateRequest } from '@agentero/grpc-clients/grpc/agent-fe';
import { handleGrpcResponse } from '@agentero/service-errors';

import { agentFrontendService } from 'packages/services/grpcClients';

export type MutateAgentLicenseArgs = {
	token: string;
	userId: string;
	data: Record<string, string | boolean>;
};

export const updateAgentUIState = ({ token, userId, data }: MutateAgentLicenseArgs) =>
	new Promise<void>((resolve, reject) => {
		const request = new UpdateAgentUIStateRequest();

		request.setState(Struct.fromJavaScript(data));

		agentFrontendService.updateAgentUIState(
			request,
			{ authorization: `Bearer ${token}` },
			error => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}

				resolve();
			}
		);
	});
