import { OperateAs as OperateAsProto } from '@agentero/grpc-clients/grpc/agency-fe';

export enum OperateAs {
	Unspecified = 'unspecified',
	Agency = 'agency',
	Agent = 'agent'
}

const operateAsMap: { [key in OperateAsProto]: OperateAs } = {
	[OperateAsProto.OPERATE_AS_UNSPECIFIED]: OperateAs.Unspecified,
	[OperateAsProto.OPERATE_AS_AGENCY]: OperateAs.Agency,
	[OperateAsProto.OPERATE_AS_AGENT]: OperateAs.Agent
};

export const parseOperateAs = (operateAs: OperateAsProto): OperateAs => operateAsMap[operateAs];

const operateAsMapToProto: { [key in OperateAs]: OperateAsProto } = {
	[OperateAs.Unspecified]: OperateAsProto.OPERATE_AS_UNSPECIFIED,
	[OperateAs.Agency]: OperateAsProto.OPERATE_AS_AGENCY,
	[OperateAs.Agent]: OperateAsProto.OPERATE_AS_AGENT
};

export const parseOperateAsToProto = (operateAs: OperateAs): OperateAsProto =>
	operateAsMapToProto[operateAs];
