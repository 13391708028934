import { InsuranceType } from '../opportunity';
import { AgentSession } from './AgentSession';
import { CurrentExpertResponse } from './CarrierSubscription';

export type AgentAgency = {
	id: string;
	name: string;
	formStackName?: string;
	quotableLobsList?: InsuranceType[];
};

export const parseAgentAgency = (
	currentExpert: CurrentExpertResponse,
	agentSession?: AgentSession
): AgentAgency => ({
	id: currentExpert.attributes.agencyId ?? '',
	name: currentExpert.attributes.agencyName ?? '',
	formStackName: currentExpert.attributes.formstackName,
	quotableLobsList: agentSession?.quotableLobsList
});
