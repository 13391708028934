import { FetchQueryOptions, UseQueryOptions } from '@tanstack/react-query';

import { GrpcRequest, useAgenteroNoSuspenseQuery, useAgenteroQuery } from '@agentero/query';

import { queryClient } from '../QueryCache';
import { useAgentResource } from './back-ag/useAgentResource';

export type GrpcPortalRequest<TRequest, TResponse> = {
	request: (requestData: TRequest & AgenteroUserData) => Promise<TResponse>;
	key: (requestData: TRequest & AgenteroUserData) => (string | number | object)[];
	useBackAgToken?: boolean;
};

export type GrpcPartialRequest<TRequest, TResponse> = {
	request: (requestData: TRequest & Partial<AgenteroUserData>) => Promise<TResponse>;
	key: (requestData: TRequest & Partial<AgenteroUserData>) => (string | number | object)[];
	useBackAgToken?: boolean;
};

export type AgenteroUserData = {
	token: string;
	userId: string;
	headers?: Record<string, string>;
};

export const useAgNoSuspenseQuery = <TRequest, TResponse>(
	queryConfig: GrpcPortalRequest<TRequest, TResponse>,
	request?: TRequest,
	reactQueryConfig?: Omit<UseQueryOptions<TResponse>, 'suspense' | 'useErrorBoundary'>
) => {
	const {
		data: { token, id: userId, backAgToken }
	} = useAgentResource();

	const queryToken = queryConfig.useBackAgToken ? backAgToken : token;

	const config: GrpcRequest<TRequest, TResponse> = {
		request: (requestData?: TRequest) => queryConfig.request({ token, userId, ...requestData }),
		key: (requestData?: TRequest) => queryConfig.key({ token: queryToken, userId, ...requestData })
	};

	return useAgenteroNoSuspenseQuery(config, request, reactQueryConfig);
};

export const useAgQuery = <TRequest, TResponse>(
	queryConfig: GrpcPortalRequest<TRequest, TResponse>,
	request?: TRequest,
	reactQueryConfig?: Omit<UseQueryOptions<TResponse>, 'suspense' | 'useErrorBoundary'>
) => {
	const {
		data: { token, id: userId, backAgToken }
	} = useAgentResource();

	const queryToken = queryConfig.useBackAgToken ? backAgToken : token;

	const config: GrpcRequest<TRequest, TResponse> = {
		request: (requestData?: TRequest) => queryConfig.request({ token, userId, ...requestData }),
		key: (requestData?: TRequest) => queryConfig.key({ token: queryToken, userId, ...requestData })
	};

	return useAgenteroQuery(config, request, reactQueryConfig);
};

export const fetchAgQuery = <TRequest, TResponse>(
	queryConfig: GrpcPartialRequest<TRequest, TResponse> | GrpcPortalRequest<TRequest, TResponse>,
	requestData: TRequest & AgenteroUserData,
	config?: FetchQueryOptions<unknown, unknown, TResponse>
) =>
	queryClient.fetchQuery(queryConfig.key(requestData), () => queryConfig.request(requestData), {
		staleTime: Infinity,
		retry: 1,
		...config
	});

export const prefetchAgQuery = <TRequest, TResponse>(
	queryConfig: GrpcPortalRequest<TRequest, TResponse>,
	requestData: TRequest & AgenteroUserData,
	config?: FetchQueryOptions<unknown, unknown, TResponse>
) =>
	queryClient.prefetchQuery(queryConfig.key(requestData), () => queryConfig.request(requestData), {
		staleTime: Infinity,
		retry: 1,
		...config
	});
