import { useState } from 'react';

import { Modal, ModalContent, Spinner } from '@agentero/components';
import { BwaTrainingResponse } from '@agentero/models';
import { Carrier } from '@agentero/models/shared';
import { Flex } from '@agentero/styles/jsx';

import { useAgencyBwaTrainingMutation } from 'packages/services/mutate/agency-fe/useAgencyBwaTrainingMutation/useAgencyBwaTrainingMutation';

import { BwaTrainingAssesment } from './bindWithAgenteroTrainingModal/BwaTrainingAssesment';
import { BwaTrainingSuccess } from './bindWithAgenteroTrainingModal/BwaTrainingSuccess';
import { BwaTrainingVideoStep } from './bindWithAgenteroTrainingModal/BwaTrainingVideoStep';

type BindWithAgenteroTrainingModalProps = {
	isOpen: boolean;
	onDismiss: () => void;
	carrier: Carrier;
};

enum AssesmentStatus {
	VideoTraining,
	Assesment,
	AssesmentFinish
}

export const BindWithAgenteroTrainingModal = ({
	carrier,
	isOpen,
	onDismiss
}: BindWithAgenteroTrainingModalProps) => {
	const [assesmentStatus, setAssesmentStatus] = useState<AssesmentStatus>(
		AssesmentStatus.VideoTraining
	);

	const { mutate, isLoading, isSuccess } = useAgencyBwaTrainingMutation();

	const onDismissModal = () => {
		setAssesmentStatus(AssesmentStatus.VideoTraining);
		onDismiss();
	};

	const onTraningSubmit = (data: BwaTrainingResponse) => {
		setAssesmentStatus(AssesmentStatus.AssesmentFinish);
		mutate({ data, carrier });
	};

	return (
		<Modal isOpen={isOpen} onDismiss={onDismissModal}>
			<ModalContent>
				{assesmentStatus === AssesmentStatus.VideoTraining ? (
					<BwaTrainingVideoStep
						carrier={carrier}
						onDismiss={onDismissModal}
						onConfirm={() => setAssesmentStatus(AssesmentStatus.Assesment)}
					/>
				) : assesmentStatus === AssesmentStatus.Assesment ? (
					<BwaTrainingAssesment carrier={carrier} onFinish={onTraningSubmit} />
				) : isLoading ? (
					<Flex alignItems="center" justifyContent="center" minHeight="400">
						<Spinner />
					</Flex>
				) : (
					isSuccess && <BwaTrainingSuccess onDone={onDismissModal} />
				)}
			</ModalContent>
		</Modal>
	);
};
