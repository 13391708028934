import React from 'react';

import { Toaster } from 'sonner';

import { IconCheckCircle, IconErrorOutline, IconInfoOutline, IconWarning } from '@agentero/icons';
import { css } from '@agentero/styles/css';

export const Toast = ({ ...props }: { props?: React.HTMLAttributes<HTMLDivElement> }) => {
	return (
		<Toaster
			{...props}
			position="top-right"
			duration={5000}
			toastOptions={{
				className: css({
					boxShadow: 'token(shadows.2) !important',
					borderRadius: 'token(radii.2) !important',
					color: 'token(neutral) !important',
					gap: 'token(spacing.8) !important'
				})
			}}
			icons={{
				success: <IconCheckCircle className={css({ '& path': { fill: 'success.40' } })} />,
				info: <IconInfoOutline className={css({ '& path': { fill: 'blue.40' } })} />,
				warning: <IconWarning className={css({ '& path': { fill: 'warning.40' } })} />,
				error: <IconErrorOutline className={css({ '& path': { fill: 'danger.40' } })} />
			}}
		/>
	);
};
