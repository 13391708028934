'use client';

import {
	ColumnDef,
	OnChangeFn,
	SortingState,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	useReactTable
} from '@tanstack/react-table';

import { IconArrowUpward, IconSwapVert } from '@agentero/icons';
import { Box, HStack, Stack, VStack, styled } from '@agentero/styles/jsx';

import { Table, TableRootProps } from './Table';
import { Text } from './Text';
import { BaseSearch, DataTableFilters, Filters } from './dataTable/DataTableFilters';
import { DataTablePagination, PaginationState } from './dataTable/DataTablePagination';

const OrderIcon = styled(IconArrowUpward, {
	base: {
		width: '16',
		height: '16',
		transition: 'transform',
		transitionDuration: '0.2s'
	},
	variants: {
		direction: {
			asc: {},
			desc: {
				transform: 'rotate(180deg)'
			},
			false: {}
		}
	}
});

const SwapVertIcon = styled(IconSwapVert, {
	base: {
		width: '16',
		height: '16'
	}
});

export type SortDirection = 'asc' | 'desc';

export type SortState = {
	orderBy: string;
	direction: SortDirection;
};

type DataTableProps<TData, TValue, TSearch extends BaseSearch> = {
	data: TData[];
	columns: ColumnDef<TData, TValue>[];
	isLoading?: boolean;
	size?: 'xs' | 'sm' | 'md';
	sticky?: NonNullable<TableRootProps>['sticky'];
	enclosed?: NonNullable<TableRootProps>['enclosed'];
	search?: {
		filters: Filters<TSearch>;
		state: TSearch;
		layout: 'sidebar' | 'toolbar';
		onSearchChange: (search: TSearch) => void;
	};
	pagination?: {
		pageCount: number;
		state: PaginationState;
		onPaginationChange: (state: PaginationState) => void;
	};
	sorting?: {
		state: SortState;
		onSortingChange: (state?: SortState) => void;
	};
};

export const DataTable = <TData, TValue, TSearch extends BaseSearch>({
	data,
	columns,
	pagination,
	size = 'md',
	sticky,
	enclosed,
	search,
	isLoading = false,
	sorting
}: DataTableProps<TData, TValue, TSearch>) => {
	const tableSorting = sorting
		? sorting.state
			? [
					{
						id: sorting.state.orderBy,
						desc: sorting.state.direction === 'desc'
					}
			  ]
			: []
		: undefined;

	const onSortingChange: OnChangeFn<SortingState> = newState => {
		let newSorting = newState;

		if (typeof newState === 'function') {
			newSorting = newState(tableSorting || []);
		}

		if (Array.isArray(newSorting) && newSorting.length) {
			sorting?.onSortingChange({
				orderBy: newSorting[0].id,
				direction: newSorting[0].desc ? 'desc' : 'asc'
			});
			return;
		}

		sorting?.onSortingChange(undefined);
	};

	const table = useReactTable({
		data,
		columns,
		pageCount: pagination?.pageCount || 1,
		onSortingChange: sorting ? onSortingChange : undefined,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		manualSorting: true,
		manualPagination: true,
		state: {
			pagination: pagination
				? { pageIndex: pagination.state.offset - 1, pageSize: pagination.state.limit }
				: undefined,
			sorting: tableSorting
		}
	});

	const { layout = 'toolbar' } = search || {};

	return (
		<Stack gap="24" direction={layout === 'toolbar' ? 'column' : 'row'}>
			{search && (
				<DataTableFilters
					filters={search.filters}
					layout={layout}
					search={search.state}
					onSearchChange={search.onSearchChange}
				/>
			)}
			<Stack gap="24" flex="1" minWidth="0">
				<Box opacity={isLoading ? '0.5' : 'unset'} transition="opacity 0.15s">
					<Table.Root size={size} sticky={sticky} enclosed={enclosed}>
						<Table.Head>
							{table.getHeaderGroups().map(headerGroup => (
								<Table.Row key={headerGroup.id}>
									{headerGroup.headers.map(header => {
										return (
											<Table.Header
												key={header.id}
												{...header.getContext().header.column.columnDef.meta}>
												{header.isPlaceholder ? null : (
													<HStack
														cursor={header.column.getCanSort() ? 'pointer' : 'default'}
														onClick={header.column.getToggleSortingHandler()}
														title={
															header.column.getCanSort()
																? header.column.getNextSortingOrder() === 'asc'
																	? 'Sort ascending'
																	: header.column.getNextSortingOrder() === 'desc'
																	? 'Sort descending'
																	: 'Clear sort'
																: undefined
														}
														justifyContent={
															// @ts-expect-error
															header.getContext().header.column.columnDef.meta?.style?.textAlign ===
															'right'
																? 'end'
																: 'start'
														}>
														{flexRender(header.column.columnDef.header, header.getContext())}

														{header.column.getCanSort() && (
															<Box height="16" width="16">
																{header.column.getIsSorted() ? (
																	<OrderIcon direction={header.column.getIsSorted()} />
																) : (
																	<SwapVertIcon />
																)}
															</Box>
														)}
													</HStack>
												)}
											</Table.Header>
										);
									})}
								</Table.Row>
							))}
						</Table.Head>
						<Table.Body>
							{table.getRowModel().rows?.length ? (
								table.getRowModel().rows.map(row => (
									<Table.Row key={row.id}>
										{row.getVisibleCells().map(cell => (
											<Table.Cell key={cell.id} {...cell.getContext().cell.column.columnDef.meta}>
												{flexRender(cell.column.columnDef.cell, cell.getContext())}
											</Table.Cell>
										))}
									</Table.Row>
								))
							) : (
								<Table.Row>
									<Table.Cell colSpan={columns.length}>
										{['xs', 'sm'].includes(size) ? (
											<Text size="body.small">
												<b>No results.</b>
											</Text>
										) : (
											<VStack gap="4" marginBlock="24">
												<Text size="title.body">
													<b>No results.</b>
												</Text>
												<Text size="body.small">
													We searched far and wide and couldn't find any results for your search.
												</Text>
											</VStack>
										)}
									</Table.Cell>
								</Table.Row>
							)}
						</Table.Body>
					</Table.Root>
				</Box>

				{pagination && (
					<DataTablePagination
						table={table}
						state={pagination.state}
						onPageStateChange={pagination.onPaginationChange}
					/>
				)}
			</Stack>
		</Stack>
	);
};
