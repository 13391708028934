import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

import { IconFiberManualRecord } from '@agentero/icons';

const ButtonLoadingContainer = styled.div`
	position: relative;
	overflow: hidden;
`;

const ButtonLoadingDots = styled.div<{ isLoading: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	visibility: ${({ isLoading }) => (isLoading ? 'visible' : 'hidden')};
	transform: ${({ isLoading }) => (isLoading ? 'translateY(0)' : 'translateY(16px)')};
	opacity: ${({ isLoading }) => (isLoading ? '1' : '0')};
	transition: 0.3s cubic-bezier(0.76, 0, 0.24, 1);
`;

const ButtonLoadingDot = styled.span`
	width: 8px;
	height: 8px;
	animation: dot-fade 1s infinite cubic-bezier(0.65, 0, 0.35, 1);

	&:nth-child(2) {
		animation-delay: 0.1s;
	}

	&:nth-child(3) {
		animation-delay: 0.2s;
	}

	@keyframes dot-fade {
		0% {
			opacity: 0;
		}
		50% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	svg {
		vertical-align: top;
		width: 100%;
		height: auto;
	}
`;

const ButtonLoadingContent = styled.div<{ isLoading: boolean }>`
	position: relative;
	visibility: ${({ isLoading }) => (!isLoading ? 'visible' : 'hidden')};
	transform: ${({ isLoading }) => (!isLoading ? 'translateY(0)' : 'translateY(-16px)')};
	opacity: ${({ isLoading }) => (!isLoading ? '1' : '0')};
	transition: 0.3s cubic-bezier(0.76, 0, 0.24, 1);
`;

type ButtonLoadingProps = {
	isLoading: boolean;
	className?: string;
};

/**
 * @deprecated Use **'@agentero/ui'** *Button* component with the *loading* prop instead.
 */
export const ButtonLoading = ({
	children,
	isLoading,
	className
}: PropsWithChildren<ButtonLoadingProps>) => {
	return (
		<ButtonLoadingContainer className={className}>
			<ButtonLoadingDots isLoading={isLoading}>
				<ButtonLoadingDot>
					<IconFiberManualRecord />
				</ButtonLoadingDot>
				<ButtonLoadingDot>
					<IconFiberManualRecord />
				</ButtonLoadingDot>
				<ButtonLoadingDot>
					<IconFiberManualRecord />
				</ButtonLoadingDot>
			</ButtonLoadingDots>
			<ButtonLoadingContent isLoading={isLoading}>{children}</ButtonLoadingContent>
		</ButtonLoadingContainer>
	);
};
