export enum Role {
	Agent,
	AgencyAdmin,
	SuperAdmin
}

export const parseRole = (agencyAdmin?: boolean) => {
	if (agencyAdmin === undefined) return Role.SuperAdmin;
	if (agencyAdmin) return Role.AgencyAdmin;

	return Role.Agent;
};

export const isSuperAdmin = (role: Role): boolean => role === Role.SuperAdmin;
export const isAgencyAdmin = (role: Role): boolean => role === Role.AgencyAdmin;
