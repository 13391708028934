import { CarrierApprovalRequestRequest } from '@agentero/grpc-clients/grpc/agency-fe';
import { BwaTrainingResponse } from '@agentero/models';
import { Carrier, getCarrierProtoFromCarrier } from '@agentero/models/shared';
import { handleGrpcResponse } from '@agentero/service-errors';

import { agencyFrontendService } from 'packages/services/grpcClients';

export type MutateSendAgencyBwaTrainingArgs = {
	token: string;
	userId: string;
	carrier: Carrier;
	data: BwaTrainingResponse;
};

export const sendAgencyBwaTraining = ({
	token,
	userId,
	carrier,
	data
}: MutateSendAgencyBwaTrainingArgs) =>
	new Promise<void>((resolve, reject) => {
		const request = new CarrierApprovalRequestRequest();

		request.setCarrier(getCarrierProtoFromCarrier(carrier));
		Object.keys(data).map(key => request.getFormDataMap().set(key, data[key]));

		agencyFrontendService.carrierApprovalRequest(
			request,
			{ authorization: `Bearer ${token}` },
			error => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}

				resolve();
			}
		);
	});
