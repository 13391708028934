import React, { forwardRef } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { IconClose, IconSearch } from '@agentero/icons';

import { TerciaryButton } from './button/TerciaryButton';
import { Input, InputProps } from './form/Input';

type InputCustomProps = Omit<
	React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'value' | 'onChange'
> & {
	value: string;
	onChange: (newValue: string) => void;
};

type InputSearchProps = InputCustomProps & InputProps;

const InputSearchContainer = styled.div`
	position: relative;
`;

const InputSearchClose = styled(TerciaryButton)`
	position: absolute;
	top: 50%;
	right: 11px;
	width: 24px;
	height: 24px;
	padding: 0;
	min-height: 24px;
	translate: 0 -50%;
`;

const IconInputTextSearch = styled(IconSearch)`
	position: absolute;
	top: 50%;
	right: 11px;
	width: 24px;
	height: 24px;
	translate: 0 -50%;
`;

const InputTextSearch = styled(Input)`
	padding-right: ${({ isBig }) => (isBig ? '46px' : '32px')};

	&:focus {
		padding-right: ${({ isBig }) => (isBig ? '46px' : '32px')};
	}

	+ ${IconInputTextSearch}, + ${InputSearchClose} {
		right: ${({ isBig }) => (isBig ? '11px' : '4px')};
	}
`;

export const InputSearch = forwardRef<HTMLInputElement, InputSearchProps>(
	({ isBig, value, onChange, ...props }, ref) => {
		const { t } = useTranslation('inputSearch');

		const onSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
			onChange(event.target.value);

		return (
			<InputSearchContainer>
				<InputTextSearch
					placeholder={t('search')}
					{...props}
					type="text"
					value={value}
					onChange={onSearchChange}
					isBig={isBig}
					ref={ref}
				/>
				{value ? (
					<InputSearchClose type="button" onClick={() => onChange('')}>
						<IconClose />
					</InputSearchClose>
				) : (
					<IconInputTextSearch />
				)}
			</InputSearchContainer>
		);
	}
);
