// TODO: remove when everything is under portal-ui
// and use the shared Address type from /portal-ui/packages/models/shared/Address.ts
type Address = {
	street: string;
	city: string;
	state: string;
	zipCode: string;
};

export const isValidAddress = (address?: Address) =>
	address?.street && address?.zipCode && address?.city && address?.state ? true : false;

export const formatAddress = ({ street, city, state, zipCode }: Address) =>
	isValidAddress({ street, city, state, zipCode }) ? `${street}, ${city}, ${state} ${zipCode}` : '';

export const isSomeAddressInfo = (address?: Address) =>
	address?.street || address?.zipCode || address?.city || address?.state ? true : false;

export const formatIncompleteAddress = ({ street, city, state, zipCode }: Address) =>
	[street, city, [state, zipCode].filter(Boolean).join(' ')].filter(Boolean).join(', ');
