import { queryClient } from 'packages/services/QueryCache';

import { GrpcPortalRequest, useAgQuery } from '../../useAgQuery';
import { fetchAgentUIState } from './fetchAgentUIState';

export const agentUIStateKey = 'agent-ui-state';

const agentUIStateConfig: GrpcPortalRequest<{}, Record<string, boolean | string>> = {
	request: fetchAgentUIState,
	key: ({ userId }) => [agentUIStateKey, userId]
};

export const useAgentUIStateQuery = () => useAgQuery(agentUIStateConfig);

export const invalidateAgentProfile = () => queryClient.invalidateQueries([agentUIStateKey]);
