import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';

import { TerciaryButton } from '../button/TerciaryButton';

interface IPageProps {
	isSelected?: boolean;
	disabled?: boolean;
	title: string;
	onPageChange: () => void;
}

const PageButton = styled(TerciaryButton)<{ isSelected: boolean }>`
	height: 32px;
	min-width: 32px;
	padding: 0 7px;
	display: flex;
	align-items: center;
	justify-content: center;

	${({ isSelected, theme }) =>
		isSelected
			? `background: ${theme.colors.gray.lighten80}; color: ${theme.colors.gray.base};`
			: ''}

	&:disabled {
		${({ isSelected, theme }) =>
			isSelected
				? `background: ${theme.colors.gray.lighten80}; color: ${theme.colors.gray.base}; opacity: 1;`
				: ''}
	}
`;

export const Page = ({
	isSelected = false,
	disabled = false,
	children,
	title,
	onPageChange
}: PropsWithChildren<IPageProps>) => (
	<li>
		<PageButton
			title={title}
			isSelected={isSelected}
			disabled={disabled}
			onClick={isSelected ? undefined : onPageChange}>
			{children}
		</PageButton>
	</li>
);
