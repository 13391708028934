import React, { InputHTMLAttributes } from 'react';

import styled from '@emotion/styled';

import { FieldStatus } from './fieldStatus';
import { EmptyDroppable } from './fileDropDeprecated/EmptyDroppable';
import { PreviewFiles } from './fileDropDeprecated/PreviewFiles';

export enum FileDropError {
	NotValidFileType
}

const FileDropContainer = styled.div<{ height?: number }>`
	--min-height: ${props => props.height ?? 400}px;
`;

type FileDropProps = InputHTMLAttributes<HTMLInputElement> & {
	onError: (error: FileDropError) => void;
	onFilesChange: (files: File[]) => void;
	availableFilesText: string;
	availableFileTypesError: string;
	files?: File[];
	status?: FieldStatus;
	height?: number;
};

export const FileDropDeprecated = (props: FileDropProps) => {
	const { files, availableFileTypesError, onFilesChange, accept } = props;
	const status = props.status || FieldStatus.None;

	return (
		<FileDropContainer height={props.height}>
			{files && files.length > 0 ? (
				<PreviewFiles
					files={files}
					accept={accept}
					availableFileTypesError={availableFileTypesError}
					onFilesChange={onFilesChange}
				/>
			) : (
				<EmptyDroppable {...props} status={status} />
			)}
		</FileDropContainer>
	);
};
