import React, { ReactNode, useRef, useState } from 'react';

import styled from '@emotion/styled';

import { ITheme } from '@agentero/styles/emotion';

import { PrimaryGhostButton } from '../button/PrimaryGhostButton';
import { FieldStatus } from './fieldStatus';

type InputFileProps = React.InputHTMLAttributes<HTMLInputElement> & {
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	status?: FieldStatus;
	label: string | ReactNode;
	showFilename?: boolean;
};

export const defaultVariant = (theme: ITheme) => `
	color: ${theme.colors.gray.base};
`;
export const successVariant = (theme: ITheme) => `
	color: ${theme.colors.primary.base};

	${PrimaryGhostButton} {
		color: ${theme.colors.primary.base};
	}
`;
export const errorVariant = (theme: ITheme) => `
	color: ${theme.colors.status.error.base};

	${PrimaryGhostButton} {
		color: ${theme.colors.status.error.base};
		border-color: ${theme.colors.status.error.base};

		svg path {
			fill: ${theme.colors.status.error.base};
		}

		&:visited {
			color: ${theme.colors.status.error.base};
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover,
			&:focus {
				color: ${theme.colors.status.error.darken30};
				border-color: ${theme.colors.status.error.darken30};

				svg path {
					fill: ${theme.colors.status.error.darken30};
				}
			}
		}

		&:active {
			color: ${theme.colors.status.error.darken50};
			border-color: ${theme.colors.status.error.darken50};

			svg path {
				fill: ${theme.colors.status.error.darken50};
			}
		}
	}
`;
export const warningVariant = (theme: ITheme) => `
	color: ${theme.colors.status.warning.base};

	${PrimaryGhostButton} {
		color: ${theme.colors.status.warning.base};
		border-color: ${theme.colors.status.warning.base};

		&:visited {
			color: ${theme.colors.status.warning.base};
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover,
			&:focus {
				color: ${theme.colors.status.warning.darken30};
				border-color: ${theme.colors.status.warning.darken30};
			}
		}

		&:active {
			color: ${theme.colors.status.warning.darken50};
			border-color: ${theme.colors.status.warning.darken50};
		}
	}
`;
export const infoVariant = (theme: ITheme) => `
	color: ${theme.colors.status.info.base};

	${PrimaryGhostButton} {
		color: ${theme.colors.status.info.base};border-color: ${theme.colors.status.warning.base};
		border-color: ${theme.colors.status.info.base};

		&:visited {
			color: ${theme.colors.status.info.base};
		}

		@media (hover: hover) and (pointer: fine) {
			&:hover,
			&:focus {
				color: ${theme.colors.status.info.darken30};
				border-color: ${theme.colors.status.info.darken30};
			}
		}

		&:active {
			color: ${theme.colors.status.info.darken50};
			border-color: ${theme.colors.status.info.darken50};
		}
	}
`;

export const statusVariants: { [key in FieldStatus]: (theme: ITheme) => string } = {
	[FieldStatus.None]: defaultVariant,
	[FieldStatus.Success]: successVariant,
	[FieldStatus.Error]: errorVariant,
	[FieldStatus.Warning]: warningVariant,
	[FieldStatus.Info]: infoVariant
};

type GetStatusVariant = {
	status: FieldStatus;
	theme: ITheme;
};

const getStatusVariant = ({ status, theme }: GetStatusVariant) => {
	const variant = statusVariants[status];
	return variant(theme);
};

const InputFileContainer = styled.div<{ status: FieldStatus }>`
	display: flex;
	align-items: center;
	${getStatusVariant}

	input[type='file'] {
		display: none;
	}
`;

const InputFileName = styled.div`
	margin-left: 8px;
	min-width: 0;
	${({ theme }) => theme.textSettings.S};
`;

const InputFileNameText = styled.div`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

export const InputFile: React.FC<InputFileProps> = ({
	onChange,
	label,
	status = FieldStatus.None,
	showFilename = true,
	...props
}) => {
	const [fileName, setFileName] = useState('');
	const inputFileRef = useRef<HTMLInputElement>(null);

	const onClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		event.currentTarget.blur();
		inputFileRef?.current?.click();
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		event.target && event.target.files && setFileName(event.target.files[0].name);
		onChange && onChange(event);
	};

	return (
		<InputFileContainer status={status}>
			<PrimaryGhostButton onClick={onClick} type="button">
				{label}
			</PrimaryGhostButton>
			{!!fileName && showFilename && (
				<InputFileName>
					<InputFileNameText>{fileName}</InputFileNameText>
				</InputFileName>
			)}
			<input type="file" ref={inputFileRef} {...props} onChange={handleChange} />
		</InputFileContainer>
	);
};
