import { InputHTMLAttributes, useRef, useState } from 'react';

import styled from '@emotion/styled';

import { IconUploadFile } from '@agentero/icons';
import { FileUpload } from '@agentero/models/shared';

import { Text } from '../../Typography';
import { FileDropError } from '../FileDropDeprecated';
import { FieldStatus } from '../fieldStatus';
import { DragStatus } from './DragStatus';
import { DroppableArea } from './emptyDroppable/DroppableArea';
import { DroppableIcon } from './emptyDroppable/DroppableIcon';
import { validateFiles } from './shared/validateFiles';

const HiddenInputFile = styled.input`
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 100%;
	height: 100%;
`;

type EmptyDroppableProps = InputHTMLAttributes<HTMLInputElement> & {
	onError: (error: FileDropError) => void;
	onValueChange: (value: FileUpload) => void;
	status: FieldStatus;
	availableFilesText: string;
};

export const EmptyDroppable = (props: EmptyDroppableProps) => {
	const { onError, onValueChange, status, availableFilesText, ...inputProps } = props;
	const { accept } = inputProps;
	const [dragStatus, setDragStatus] = useState(DragStatus.None);
	const inputFileRef = useRef<HTMLInputElement>(null);

	const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = event.target.files as FileList;

		if (accept) {
			const isSomeInvalidType = validateFiles(files, accept);
			if (isSomeInvalidType) {
				onError(FileDropError.NotValidFileType);
				if (inputFileRef.current) {
					inputFileRef.current.value = '';
				}
			} else {
				onValueChange({ file: files[0], isChanged: true });
			}
		}

		setDragStatus(DragStatus.None);
	};

	const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
		if (accept) {
			const files = event.dataTransfer.items;
			//@ts-ignore
			const isSomeInvalidType = validateFiles(files, accept);

			if (isSomeInvalidType) {
				setDragStatus(DragStatus.InvalidFiles);
				return;
			}
		}

		setDragStatus(DragStatus.ValidFiles);
	};

	const onDragLeave = () => setDragStatus(DragStatus.None);

	return (
		<DroppableArea dragStatus={dragStatus} status={status}>
			<DroppableIcon dragStatus={dragStatus}>
				<IconUploadFile />
			</DroppableIcon>
			<Text size="S">
				<b>Drag and drop here</b>
			</Text>
			<Text size="S" mb={24}>
				or{' '}
				<Text size="S" as="span" colorize={colors => colors.primary.base}>
					browse the file
				</Text>
			</Text>
			<Text size="S" colorize={colors => colors.gray.lighten40}>
				{availableFilesText}
			</Text>

			<HiddenInputFile
				ref={inputFileRef}
				type="file"
				readOnly
				accept={accept}
				onDragEnter={onDragEnter}
				onDragLeave={onDragLeave}
				onChange={onInputChange}
			/>
		</DroppableArea>
	);
};
