import { PropsWithChildren, useCallback, useState } from 'react';

import styled from '@emotion/styled';

import { useMediaQuery, useOnClickOutside } from '@agentero/hooks';
import { IconSearch } from '@agentero/icons';

import { SecondaryButton } from '../button/SecondaryButton';

const ResponsiveInputSearchContainer = styled.div<{ isVisible: boolean }>`
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	width: 100%;
	display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};

	${({ theme }) => theme.mediaqueries.S} {
		position: relative;
		top: auto;
		right: auto;
		display: block;
		width: 200px;
	}

	${({ theme }) => theme.mediaqueries.M} {
		width: 280px;
	}
`;

export const ResponsiveInputSearch = ({ children }: PropsWithChildren) => {
	const [inputIsVisible, setInputIsVisible] = useState(false);
	const toggleIsVisible = useMediaQuery('(max-width: 479px)');

	const handleClickOutside = useCallback(() => setInputIsVisible(false), []);
	const stopOutsidePropagation = useOnClickOutside(handleClickOutside);

	return (
		<>
			{toggleIsVisible && !inputIsVisible && (
				<SecondaryButton {...stopOutsidePropagation} onClick={() => setInputIsVisible(true)}>
					<IconSearch />
				</SecondaryButton>
			)}
			<ResponsiveInputSearchContainer isVisible={inputIsVisible} {...stopOutsidePropagation}>
				{children}
			</ResponsiveInputSearchContainer>
		</>
	);
};
