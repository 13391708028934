import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Modal, ModalContent } from '@agentero/components';
import { IconBambooWhiteLogo } from '@agentero/icons';
import { AppointmentRequestStatus } from '@agentero/models';
import { BwaApprovalStatus } from '@agentero/models/agency';
import { FeatureFlagKey } from '@agentero/models/featureflags';
import { Carrier } from '@agentero/models/shared';
import { css } from '@agentero/styles/css';
import { HStack, Stack } from '@agentero/styles/jsx';
import { Button, Text } from '@agentero/ui';

import { useCarrierListQuery } from 'packages/services/fetch/carrier-fe/carrier-list/useCarrierListQuery';
import { useFeatureFlagsQuery } from 'packages/services/fetch/feature-flags-fe/useFeatureFlagsQuery';
import { useUserSettings } from 'packages/services/user-settings/useUserSettings';

import { BindWithAgenteroTrainingModal } from './BindWithAgenteroTrainingModal';

export const BindWithAgenteroTrainingFirstLoginModal = () => {
	const { t } = useTranslation(['bindRequest']);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
	const [isTrainingOpen, setIsTrainingOpen] = useState<boolean>(false);
	const isFlagActive = useFeatureFlagsQuery()[FeatureFlagKey.BambooModalLogin];

	const { isSettingActive, deactivateSetting } = useUserSettings('bamboo-log-in-modal');

	const { data } = useCarrierListQuery({
		search: {
			page: 1,
			filters: {
				searchTerm: ''
			}
		}
	});

	const bambooEligible = data.some(
		carrierItem =>
			carrierItem.carrier === Carrier.Bamboo &&
			carrierItem.appointmentRequestStatus !== AppointmentRequestStatus.NotApproved &&
			carrierItem.appointmentRequestStatus !== AppointmentRequestStatus.Approved &&
			carrierItem.bwaApprovalStatus === BwaApprovalStatus.NotRequested
	);

	const onDismiss = () => {
		deactivateSetting();
		setIsModalOpen(false);
	};

	const onTrainingOpen = () => {
		setIsTrainingOpen(true);
	};

	const onTrainingDismiss = () => {
		setIsTrainingOpen(false);
		onDismiss();
	};

	return isFlagActive && isSettingActive && bambooEligible ? (
		<>
			<Modal isOpen={isModalOpen} onDismiss={onDismiss} size="xl">
				<ModalContent>
					<HStack width="100%">
						<Stack paddingY="54" paddingX="48" flex="1">
							<Text size="title.section">{t('bwa-training.log-in-modal.title')}</Text>
							<Text size="body.small">{t('bwa-training.log-in-modal.body')}</Text>
							<Stack alignSelf="flex-start">
								<Button onClick={onTrainingOpen}>{t('bwa-training.log-in-modal.button')}</Button>
							</Stack>
						</Stack>
						<Stack
							height="100%"
							backgroundColor="#e0552b"
							alignItems="center"
							justifyContent="center"
							borderLeftRadius="50%"
							flex="1">
							<IconBambooWhiteLogo className={css({ paddingLeft: '54', paddingRight: '16' })} />
						</Stack>
					</HStack>
				</ModalContent>
			</Modal>
			<BindWithAgenteroTrainingModal
				isOpen={isTrainingOpen}
				onDismiss={onTrainingDismiss}
				carrier={Carrier.Bamboo}
			/>
		</>
	) : null;
};
