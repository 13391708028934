import i18n from 'i18next';

// TODO: Create script to autogenerate this imports to avoid overhead
import alertLocalesUS from '@agentero/components/src/content/assets/locales/alert/en-US.json';
import appliedFiltersLocalesUS from '@agentero/components/src/content/assets/locales/appliedFilters/en-US.json';
import confirmLocalesUS from '@agentero/components/src/content/assets/locales/confirm/en-US.json';
import grpcErrorManagerLocalesUS from '@agentero/components/src/content/assets/locales/grpc-error-manager/en-US.json';
import inputSearchLocalesUS from '@agentero/components/src/content/assets/locales/inputSearch/en-US.json';
import modalLocalesUS from '@agentero/components/src/content/assets/locales/modal/en-US.json';
import navLocalesUS from '@agentero/components/src/content/assets/locales/nav/en-US.json';
import pagerLocalesUS from '@agentero/components/src/content/assets/locales/pager/en-US.json';
import panelLocalesUS from '@agentero/components/src/content/assets/locales/panel/en-US.json';
import schedulerLocalesUS from '@agentero/components/src/content/assets/locales/scheduler/en-US.json';
import inputVINUS from '@agentero/components/src/content/assets/locales/inputVIN/en-US.json';

// COMPONENTS
import onboardingLocalesUS from 'packages/locales/components/onboarding/en-US.json';
import featureModalUS from 'packages/locales/components/featureModal/en-US.json';
import emailPanelUS from 'packages/locales/components/emailPanel/en-US.json';
import noResultsUS from 'packages/locales/components/noResults/en-US.json';
import toggleUS from '@agentero/components/src/content/assets/locales/toggle/en-US.json';
import contactFormUS from 'packages/locales/components/contactForm/en-US.json';
import whatsNewUS from 'packages/locales/components/whatsNew/en-US.json';
import sequenceStepsUS from 'packages/locales/components/sequenceSteps/en-US.json';
import authUS from 'packages/locales/components/auth/en-US.json';
import carrierCredentialsUS from 'packages/locales/components/carrierCredentials/en-US.json';
import sharedFAQsUS from 'packages/locales/components/sharedFAQs/en-US.json';

// PAGES
import dashboardLocalesUS from 'packages/locales/pages/dashboard/en-US.json';
import errorLocalesUS from 'packages/locales/pages/error/en-US.json';
import invitationsSharedUS from 'packages/locales/pages/invitations/invitationsShared/en-US.json';
import invitationsDashboardUS from 'packages/locales/pages/invitations/invitationsDashboard/en-US.json';
import inviteContactsUS from 'packages/locales/pages/invitations/inviteContacts/en-US.json';
import policiesUS from 'packages/locales/pages/policies/en-US.json';
import policyCreateOpportunityUS from 'packages/locales/pages/policyCreateOpportunity/en-US.json';
import invitationsSequencesUS from 'packages/locales/pages/invitations/invitationsSequences/en-US.json';
import unsubscribeUS from 'packages/locales/pages/unsubscribe/en-US.json';
import integrationsUS from 'packages/locales/pages/integrations/en-US.json';
import adminAgencyCarriersUS from 'packages/locales/pages/admin/adminAgencyCarriers/en-US.json';
import marketAccessUS from 'packages/locales/pages/marketAccess/en-US.json';
import commissionsUS from 'packages/locales/pages/commissions/en-US.json';
import sendProposalUS from 'packages/locales/pages/sendProposal/en-US.json';
import appointmentsUS from 'packages/locales/pages/appointments/en-US.json';
import editCarrierUS from 'packages/locales/pages/appointments/editCarrier/en-US.json';
import activateUS from 'packages/locales/pages/activate/en-US.json';
import signInUS from 'packages/locales/pages/signIn/en-US.json';
import forgotPasswordUS from 'packages/locales/pages/forgotPassword/en-US.json';
import templatesUS from 'packages/locales/pages/templates/en-US.json';
import agentsUS from 'packages/locales/pages/agents/en-US.json';
import agenciesUS from 'packages/locales/pages/agencies/en-US.json';
import raterUS from 'packages/locales/pages/rater/en-US.json';
import resetPasswordUS from 'packages/locales/pages/resetPassword/en-US.json';
import sharedLocalesUS from 'packages/locales/shared/en-US.json';
import updatePasswordUS from 'packages/locales/pages/updatePassword/en-US.json';
import appetiteUS from 'packages/locales/pages/appetite/en-US.json';
import settingsUS from 'packages/locales/pages/settings/en-US.json';
import applicationsUS from 'packages/locales/pages/applications/en-US.json';
import accountVerificationUS from 'packages/locales/pages/accountVerification/en-US.json';
import signUpUS from 'packages/locales/pages/signUp/en-US.json';
import bindRequestUS from 'packages/locales/pages/bindRequest/en-US.json';
import paymentUS from 'packages/locales/pages/payment/en-US.json';
import carriersUS from 'packages/locales/pages/carriers/en-US.json';
import leadsUS from 'packages/locales/pages/leads/en-US.json';

// MODULES
import tierUS from 'packages/locales/modules/tier/en-US.json';

const LANGUAGES = ['en-US'];
const DEFAULTLANGUAGES = 'en-US';

//TODO: We can remove LanguageDetector and i18nextXHR when everything is migrated to next

i18n.init({
	supportedLngs: LANGUAGES,
	lng: DEFAULTLANGUAGES,
	fallbackLng: DEFAULTLANGUAGES,
	ns: ['shared'],
	resources: {
		'en-US': {
			alert: alertLocalesUS,
			appliedFilters: appliedFiltersLocalesUS,
			confirm: confirmLocalesUS,
			grpcErrorManager: grpcErrorManagerLocalesUS,
			inputSearch: inputSearchLocalesUS,
			modal: modalLocalesUS,
			nav: navLocalesUS,
			pager: pagerLocalesUS,
			panel: panelLocalesUS,
			scheduler: schedulerLocalesUS,
			inputVIN: inputVINUS,
			onboarding: onboardingLocalesUS,
			featureModal: featureModalUS,
			error: errorLocalesUS,
			dashboard: dashboardLocalesUS,
			shared: sharedLocalesUS,
			invitationsShared: invitationsSharedUS,
			invitationsDashboard: invitationsDashboardUS,
			inviteContacts: inviteContactsUS,
			emailPanel: emailPanelUS,
			policies: policiesUS,
			policyCreateOpportunity: policyCreateOpportunityUS,
			noResults: noResultsUS,
			invitationsSequences: invitationsSequencesUS,
			toggle: toggleUS,
			contactForm: contactFormUS,
			whatsNew: whatsNewUS,
			sequenceSteps: sequenceStepsUS,
			unsubscribe: unsubscribeUS,
			integrations: integrationsUS,
			adminAgencyCarriers: adminAgencyCarriersUS,
			marketAccess: marketAccessUS,
			sendProposal: sendProposalUS,
			commissions: commissionsUS,
			appointments: appointmentsUS,
			editCarrier: editCarrierUS,
			auth: authUS,
			activate: activateUS,
			signIn: signInUS,
			forgotPassword: forgotPasswordUS,
			templates: templatesUS,
			agents: agentsUS,
			agencies: agenciesUS,
			rater: raterUS,
			resetPassword: resetPasswordUS,
			updatePassword: updatePasswordUS,
			carrierCredentials: carrierCredentialsUS,
			sharedFAQs: sharedFAQsUS,
			appetite: appetiteUS,
			settings: settingsUS,
			applications: applicationsUS,
			accountVerification: accountVerificationUS,
			signUp: signUpUS,
			bindRequest: bindRequestUS,
			payment: paymentUS,
			tier: tierUS,
			carriers: carriersUS,
			leads: leadsUS
		}
	},
	debug: false,
	interpolation: {
		escapeValue: false,
		formatSeparator: ','
	},
	react: {
		useSuspense: false
	}
});

export { i18n };
