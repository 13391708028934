import { PropsWithChildren, ReactElement } from 'react';

import styled from '@emotion/styled';

import { Card } from '@agentero/components';
import { IconEdit } from '@agentero/icons';
import { Button } from '@agentero/ui';

import { DeleteItemButton } from './editCard/DeleteItemButton';

const EditCardRow = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.sizes.getSize(1)};
`;

const EditCardBodyContainer = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	gap: 8px;
`;

export const EditCardIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 0 1 ${({ theme }) => `${theme.sizes.getSize(5)}`};
	width: ${({ theme }) => `${theme.sizes.getSize(5)}`};
	min-width: ${({ theme }) => `${theme.sizes.getSize(5)}`};
	height: ${({ theme }) => `${theme.sizes.getSize(5)}`};
	background: ${({ theme }) => theme.colors.gray.lighten95};
	border-radius: 50%;
	text-transform: uppercase;
	color: ${({ theme }) => theme.colors.gray.lighten40};
	${({ theme }) => theme.textSettings.M}

	> svg {
		path {
			fill: ${({ theme }) => theme.colors.gray.lighten40};
		}
	}
`;

const EditCardActions = styled.div`
	display: flex;
	gap: ${({ theme }) => `${theme.sizes.getSize(1)}`};
	flex: 0 0 auto;
	margin-left: auto;
`;

const EditCardData = styled.div`
	margin-top: ${({ theme }) => `${theme.sizes.getSize(3)}`};
`;
type DeleteConfig = {
	onDelete: () => void;
	item: string;
	name: string;
};

type EditCardProps = {
	Icon?: ReactElement;
	title: string | ReactElement;
	disabled?: boolean;
	onEdit?: () => void;
	deleteConfig?: DeleteConfig;
	isEditing?: boolean;
	isPreviewValues?: boolean;
	isEditable?: boolean;
};

export const EditCard = ({
	Icon,
	title,
	disabled = false,
	isEditable = false,
	children,
	onEdit,
	deleteConfig,
	isEditing,
	isPreviewValues = true
}: PropsWithChildren<EditCardProps>) => {
	return (
		<Card data-cy="add-credentials-modal-card" hasBorder role="treeitem" aria-expanded={isEditing}>
			<EditCardRow>
				{Icon && <EditCardIcon>{Icon}</EditCardIcon>}
				<EditCardBodyContainer>{title}</EditCardBodyContainer>
				{!isEditing && isEditable && (
					<EditCardActions>
						{deleteConfig && <DeleteItemButton {...deleteConfig} disabled={disabled} />}
						<Button
							variant="tertiary"
							onClick={onEdit}
							disabled={disabled}
							aria-label="Edit"
							data-cy="edit-button">
							<IconEdit />
						</Button>
					</EditCardActions>
				)}
			</EditCardRow>
			{(isPreviewValues || isEditing) && <EditCardData>{children}</EditCardData>}
		</Card>
	);
};
