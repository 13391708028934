import {
	AnchorHTMLAttributes,
	ButtonHTMLAttributes,
	Children,
	ForwardedRef,
	PropsWithChildren,
	forwardRef,
	isValidElement
} from 'react';

import Link, { LinkProps } from 'next/link';

import { cva } from '@agentero/styles/css';
import { styled } from '@agentero/styles/jsx';
import { RecipeVariantProps } from '@agentero/styles/types';

const buttonStyle = cva({
	base: {
		position: 'relative',
		display: 'inline-flex',
		alignItems: 'center',
		fontWeight: '600',
		borderRadius: 'sm',
		cursor: 'pointer',
		transition: 'background 0.2s',
		border: '1px solid transparent',
		textDecoration: 'none',
		whiteSpace: 'nowrap',
		WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)',
		'&:disabled': {
			pointerEvents: 'none',
			opacity: '0.3'
		},
		'&:focus-visible': {
			outlineColor: 'button.outline',
			outlineOffset: '0.125rem',
			outlineStyle: 'solid',
			outlineWidth: '0.125rem'
		}
	},
	variants: {
		variant: {
			primary: {
				backgroundColor: 'button.bg.primary',
				color: 'button.text.primary',
				fill: 'button.text.primary',
				borderColor: 'button.border.primary',
				'& svg path': {
					fill: 'button.text.primary'
				},
				'&:hover': {
					backgroundColor: 'button.bg.primary.hover',
					borderColor: 'button.border.primary.hover'
				},
				'&:active': {
					backgroundColor: 'button.bg.primary.active',
					borderColor: 'button.border.primary.active'
				}
			},
			secondary: {
				backgroundColor: 'button.bg.secondary',
				color: 'button.text.secondary',
				borderColor: 'button.border.secondary',
				'&:hover': {
					backgroundColor: 'button.bg.secondary.hover'
				},
				'&:active': {
					backgroundColor: 'button.bg.secondary.active'
				}
			},
			tertiary: {
				backgroundColor: 'button.bg.tertiary',
				color: 'button.text.tertiary',
				borderColor: 'button.border.tertiary',
				'&:hover': {
					backgroundColor: 'button.bg.tertiary.hover'
				},
				'&:active': {
					backgroundColor: 'button.bg.tertiary.active'
				}
			},
			link: {
				paddingInline: '0',
				textDecoration: 'underline',
				textUnderlineOffset: 'var(--text-underline-offset)'
			}
		},
		size: {
			xs: {
				gap: '8',
				textStyle: 'caption.base',
				height: '1.5rem',
				minWidth: '4rem',
				paddingInline: '8',
				'& svg': {
					width: '0.875rem',
					height: '0.875rem',
					marginInline: '-1'
				}
			},
			sm: {
				gap: '8',
				textStyle: 'body.small',
				height: '2rem',
				minWidth: '5.375rem',
				paddingInline: '12',
				'& svg': {
					width: '1.125rem',
					height: '1.125rem',
					marginInline: '-2'
				}
			},
			md: {
				gap: '8',
				textStyle: 'body.small',
				height: '2.5rem',
				paddingInline: '16',
				minWidth: '6.25rem',
				'& svg': {
					width: '1.25rem',
					height: '1.25rem',
					marginInline: '-2'
				}
			},
			lg: {
				gap: '12',
				textStyle: 'body',
				height: '3rem',
				minWidth: '7.5rem',
				paddingInline: '20',
				'& svg': {
					marginInline: '-4'
				}
			}
		},
		status: {
			danger: {
				'&:focus-visible': {
					outlineColor: 'button.outline.status.danger'
				}
			}
		},
		hasOnlyIcon: {
			true: {
				aspectRatio: 1,
				paddingInline: '0',
				minWidth: 'unset'
			}
		},
		disabled: {
			true: {
				pointerEvents: 'none',
				opacity: '0.3'
			}
		},
		rounded: {
			true: {
				borderRadius: 'full'
			}
		},
		align: {
			center: {
				justifyContent: 'center'
			},
			start: {
				justifyContent: 'flex-start'
			},
			end: {
				justifyContent: 'flex-end'
			},
			justify: {
				justifyContent: 'space-between'
			}
		}
	},
	compoundVariants: [
		{
			variant: 'primary',
			status: 'danger',
			css: {
				color: 'button.text.primary.status.danger',
				backgroundColor: 'button.bg.primary.status.danger',
				borderColor: 'button.border.primary.status.danger',
				'&:hover': {
					backgroundColor: 'button.bg.primary.status.danger.hover',
					borderColor: 'button.border.primary.status.danger.hover'
				},
				'&:active': {
					backgroundColor: 'button.bg.primary.status.danger.active',
					borderColor: 'button.border.primary.status.danger.active'
				}
			}
		},
		{
			variant: 'secondary',
			status: 'danger',
			css: {
				color: 'button.text.secondary.status.danger',
				borderColor: 'button.border.secondary.status.danger',
				'&:hover': {
					backgroundColor: 'button.bg.secondary.status.danger.hover'
				},
				'&:active': {
					backgroundColor: 'button.bg.secondary.status.danger.active'
				}
			}
		},
		{
			variant: 'tertiary',
			status: 'danger',
			css: {
				color: 'button.text.tertiary.status.danger',
				'&:hover': {
					backgroundColor: 'button.bg.tertiary.status.danger.hover'
				},
				'&:active': {
					backgroundColor: 'button.bg.tertiary.status.danger.active'
				}
			}
		},
		{
			variant: 'link',
			status: 'danger',
			css: {
				color: 'button.text.link.status.danger'
			}
		},
		{
			variant: 'link',
			size: ['xs', 'sm', 'md', 'lg'],
			css: {
				gap: '4',
				paddingBlock: '0',
				paddingInline: '0',
				height: 'auto',
				minWidth: 'unset',
				'& svg': {
					marginInline: '0'
				}
			}
		}
	],
	defaultVariants: {
		variant: 'primary',
		size: 'sm',
		align: 'center'
	}
});

export type ButtonVaritantType = NonNullable<RecipeVariantProps<typeof buttonStyle>>['variant'];

export type ButtonSizeType = NonNullable<RecipeVariantProps<typeof buttonStyle>>['size'];

type ButtonBaseProps = Omit<
	{
		as?: 'a' | 'link' | 'button';
		loading?: boolean;
		disabled?: boolean;
	} & RecipeVariantProps<typeof buttonStyle>,
	'hasOnlyIcon'
>;

export type ButtonAsAnchor = ButtonBaseProps &
	AnchorHTMLAttributes<HTMLAnchorElement> & { as?: 'a' };
type ButtonAsButton = ButtonBaseProps & ButtonHTMLAttributes<HTMLButtonElement> & { as?: 'button' };
type ButtonAsLink = ButtonBaseProps &
	LinkProps &
	AnchorHTMLAttributes<HTMLAnchorElement> & { as?: 'link' };

export type ButtonProps = ButtonAsButton | ButtonAsAnchor | ButtonAsLink;

const ButtonLoading = styled('div', {
	base: {
		position: 'absolute',
		top: '0',
		left: '0',
		right: '0',
		bottom: '0',
		display: 'grid',
		placeItems: 'center',
		borderRadius: 'inherit',
		backgroundColor: 'inherit',
		opacity: '0',
		animation: 'fadeIn .3s forwards',
		'&:before': {
			content: '""',
			display: 'block',
			height: '1em',
			width: '1em',
			borderWidth: '0.125rem',
			borderStyle: 'solid',
			borderRadius: 'full',
			borderColor: 'inherit',
			borderTopColor: 'transparent',
			animation: 'spinAround .5s infinite linear'
		}
	}
});

export const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
	(
		{
			children,
			as = 'button',
			variant,
			size = 'sm',
			loading,
			disabled,
			rounded,
			status,
			align,
			...props
		}: PropsWithChildren<ButtonProps>,
		ref
	) => {
		const hasOnlyIcon = Children.toArray(children).every(value => isValidElement(value));
		const isDisabled = disabled || loading;
		const className = buttonStyle({
			variant,
			size,
			hasOnlyIcon,
			disabled: isDisabled,
			status,
			rounded,
			align
		});

		if (loading && variant === 'link') {
			throw new Error('Button with variant link and loading is not supported');
		}

		if (hasOnlyIcon && variant === 'link') {
			throw new Error(
				'Button with no text and variant link is not supported. Use it with variant tertiary instead'
			);
		}

		const content = (
			<>
				{loading && <ButtonLoading aria-label="loading" role="status" />}
				{children}
			</>
		);

		if (as === 'a') {
			return (
				<a
					className={className}
					{...(props as ButtonAsAnchor)}
					ref={ref as ForwardedRef<HTMLAnchorElement>}>
					{content}
				</a>
			);
		}

		if (as === 'link') {
			return (
				<Link
					className={className}
					{...(props as ButtonAsLink)}
					ref={ref as ForwardedRef<HTMLAnchorElement>}>
					{content}
				</Link>
			);
		}

		return (
			<button
				className={className}
				{...(props as ButtonAsButton)}
				disabled={isDisabled}
				ref={ref as ForwardedRef<HTMLButtonElement>}>
				{content}
			</button>
		);
	}
);
