import { FC } from 'react';

import styled from '@emotion/styled';

type AvatarSize = 'XXL' | 'XL' | 'L' | 'S' | 'XS';

type AvatarProps = {
	src?: string;
	firstName?: string;
	lastName?: string;
	className?: string;
	size?: AvatarSize;
};

const avatarSizeMapping: { [key in AvatarSize]: number } = {
	['XXL']: 80,
	['XL']: 48,
	['L']: 40,
	['S']: 32,
	['XS']: 16
};

const avatarTextSettingsMapping: { [key in AvatarSize]: string } = {
	['XXL']: 'XXL',
	['XL']: 'XL',
	['L']: 'L',
	['S']: 'S',
	['XS']: 'XXS'
};

const AvatarContainer = styled.div<{ size: AvatarSize }>`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${({ size }) => avatarSizeMapping[size]}px;
	min-width: ${({ size }) => avatarSizeMapping[size]}px;
	height: ${({ size }) => avatarSizeMapping[size]}px;
	color: ${({ theme }) => theme.colors.primary.base};
	${({ theme, size }) => theme.textSettings[avatarTextSettingsMapping[size]]};
	background: ${({ theme }) => theme.colors.white};
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten80};
	border-radius: 50%;
	overflow: hidden;
`;

const AvatarImage = styled.img`
	width: 100%;
	height: 100%;
	overflow: hidden;
	object-fit: cover;
`;

/**
 * @deprecated Use **'@agentero/ui'** *Avatar* instead.
 */
export const Avatar: FC<AvatarProps> = ({
	src,
	firstName = '',
	lastName,
	className,
	size = 'S'
}) => {
	return (
		<AvatarContainer className={className} size={size}>
			{!!src ? (
				<AvatarImage
					src={src}
					title={`${firstName} ${lastName}`}
					alt={`${firstName} ${lastName}`}
					width={avatarSizeMapping[size]}
					height={avatarSizeMapping[size]}
				/>
			) : (
				<div>{firstName[0]}</div>
			)}
		</AvatarContainer>
	);
};

export const DarkAvatar = styled(Avatar)`
	color: ${({ theme }) => theme.colors.white};
	background: ${({ theme }) => theme.colors.gray.base};
	border-color: ${({ theme }) => theme.colors.gray.base};
`;

export const GreyAvatar = styled(Avatar)`
	color: ${({ theme }) => theme.colors.gray.lighten40};
	background: ${({ theme }) => theme.colors.gray.lighten80};
	border-color: ${({ theme }) => theme.colors.gray.lighten80};
`;

export const OrangeAvatar = styled(Avatar)`
	color: ${({ theme }) => theme.colors.gray.base};
	background: ${({ theme }) => theme.colors.status.warning.lighten40};
	border-color: ${({ theme }) => theme.colors.status.warning.lighten40};
`;
