import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormGroupRadio } from '@agentero/components';
import { BwaTrainingQuestion, BwaTrainingResponse } from '@agentero/models';
import { Stack } from '@agentero/styles/jsx';
import { Form, Text } from '@agentero/ui';

type BwaTrainingQuestionFormProps = {
	formId: string;
	question: BwaTrainingQuestion;
	onSubmit: (data: Partial<BwaTrainingResponse>) => void;
};

export const BwaTrainingQuestionForm = ({
	formId,
	question,
	onSubmit
}: BwaTrainingQuestionFormProps) => {
	const methods = useForm<Partial<BwaTrainingResponse>>();
	const { t } = useTranslation('shared');

	return (
		<Form methods={methods} ariaLabel="bwa-training" id={formId} onSubmit={onSubmit}>
			<Stack gap="4">
				<Text>
					<b>{question.question}</b>
				</Text>
				<FormGroupRadio
					label=""
					values={question.options}
					{...methods.register(question.destination, {
						required: { value: true, message: t('form.mandatory') }
					})}
					displayErrorMessage
				/>
			</Stack>
		</Form>
	);
};
