import styled from '@emotion/styled';

import { Button } from '../Button';
import { IconTextContainer } from '../IconText';

/**
 * @deprecated Use `Button` with variant `link` from `@agentero/ui` instead.
 */
export const LinkButton = styled(Button)`
	background: transparent;
	padding-left: 0;
	padding-right: 0;
	color: ${({ theme }) => theme.colors.gray.base};
	text-decoration: underline;
	text-underline-offset: var(--text-underline-offset);

	${IconTextContainer} {
		margin: 0 -8px 0 -1px;
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover,
		&:focus {
			text-decoration: underline;
			color: ${({ theme }) => theme.colors.gray.base};
		}
	}

	&:active {
		color: ${({ theme }) => theme.colors.gray.base};
	}
`;

/**
 * @deprecated Use `Button` with variant `link` and as `a` from `@agentero/ui` instead.
 */
export const LinkButtonLink = LinkButton.withComponent('a');
