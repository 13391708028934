export const parsePhoneNumber = (phoneNumber: string) =>
	phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');

// Converts a phone number with the pattern (XXX) XXX-XXXX to E.164 format
export const parsePhoneNumberToE164 = (phoneNumber: string) => {
	// Remove all non-numeric characters from the phone number
	const digitsOnly = phoneNumber.replace(/\D/g, '');

	// Check if the length is valid (10 digits for US numbers)
	if (digitsOnly.length !== 10) {
		throw new Error('Invalid phone number');
	}

	// Prepend country code (assuming US (+1))
	return `+1${digitsOnly}`;
};

export const parseE164ToFormattedPhoneNumber = (e164PhoneNumber: string) => {
	// Check if the number starts with + 1 and remove it if present
	const isInternational = e164PhoneNumber.startsWith('+1');

	// Remove all non-digit characters
	let digitsOnly = e164PhoneNumber.replace(/\D/g, '');

	if (isInternational) {
		digitsOnly = digitsOnly.substring(1);
	}

	// Validate the number length
	if (digitsOnly.length !== 10) {
		return `Invalid phone number: ${e164PhoneNumber}`;
	}

	// Format the phone number as (123) 123-1234
	return `(${digitsOnly.slice(0, 3)}) ${digitsOnly.slice(3, 6)}-${digitsOnly.slice(6)}`;
};
