import { RoundIcon } from '@agentero/components';
import { IconAgentero, IconSwapHorizontal } from '@agentero/icons';
import { Carrier } from '@agentero/models/shared';
import { css } from '@agentero/styles/css';
import { HStack, styled } from '@agentero/styles/jsx';

import { CarrierIcon } from 'packages/components/CarrierIcon';

type BindWithAgenteroCarrierProps = {
	carrier: Carrier;
};

const IconContainer = styled('div', {
	base: {
		marginRight: '-4px',
		marginLeft: '-4px',
		zIndex: 1,
		backgroundColor: 'white'
	}
});

export const BindWithAgenteroCarrier = ({ carrier }: BindWithAgenteroCarrierProps) => {
	return (
		<HStack gap="0">
			<RoundIcon icon={<IconAgentero css={{ padding: 8 }} />} />
			<IconContainer>
				<IconSwapHorizontal className={css({ '& path': { fill: 'neutral.70' } })} />
			</IconContainer>
			<CarrierIcon carrier={carrier} />
		</HStack>
	);
};
