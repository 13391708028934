import * as yup from 'yup';
import { useRouter } from 'next/router';

import { AppliedFilter, ORDER_DIRECTION } from '@agentero/components';

import { useAppliedFilters } from './useAgUrlState/appliedFilters';
import {
	OnFilterChangeArgs,
	ValueFromKey,
	addFilter,
	clearFilters,
	removeFilter
} from './useAgUrlState/filters';
import { getState } from './useAgUrlState/schemaObject';

export const fieldsExcludedFromSearch = ['page', 'pageSize', 'searchTerm', 'field', 'direction'];
type OmitFromSearch = 'page' | 'pageSize' | 'searchTerm' | 'field' | 'direction';

type UseAgUrlStateArgs<T extends Search<T['field']>> = {
	defaultSearch: T;
	schema: yup.AnyObjectSchema;
	translations: TranslationsOmitted<T>;
};

type UseAgUrlStateReturn<T extends Search<T['field']>> = {
	search: T;
	appliedFilters: AppliedFilter[];
	isFilterApplied: boolean;
	updateInputSearch: (search?: string) => void;
	addSearchFilter: <K extends keyof T>({
		key,
		value
	}: Omit<OnFilterChangeArgs<T, K>, 'search'>) => void;
	removeSearchFilter: <K extends keyof T>({
		key,
		value
	}: Omit<OnFilterChangeArgs<T, K>, 'search'>) => void;
	clearSearchFilters: () => void;
	updatePage: (page: number) => void;
	updateOrderBy: (field?: T['field'] | undefined, direction?: ORDER_DIRECTION) => void;
};

export type Search<O> = {
	page: number;
	pageSize: number;
	searchTerm: string;
	direction?: ORDER_DIRECTION;
	field?: O;
} & Record<string, boolean | number | string | string[]>;

export type Translations<T> = {
	[key in keyof T]: <K extends keyof T>(value: ValueFromKey<T[K]>) => string;
};

export type TranslationsOmitted<T extends Search<T['field']>> = Translations<
	Omit<T, OmitFromSearch>
>;

export const useAgUrlState = <T extends Search<T['field']>>({
	schema,
	defaultSearch,
	translations
}: UseAgUrlStateArgs<T>): UseAgUrlStateReturn<T> => {
	const router = useRouter();
	const partialState = getState({ query: router.query as Partial<T>, schema, defaultSearch });
	const search = { ...defaultSearch, ...partialState } as T;

	const {
		appliedFilters,
		isFilterApplied,
		addAppliedFilter,
		removeAppliedFilter,
		clearAppliedFilter
	} = useAppliedFilters(search, translations);

	const updateSearch = (newSearch: Partial<T>) => {
		// Stringify to remove undefined fields
		const newQuery = JSON.parse(JSON.stringify({ ...router.query, ...newSearch }));

		router.replace(
			{
				pathname: router.route,
				query: newQuery
			},
			undefined,
			{ shallow: true }
		);
	};

	const updateInputSearch = (searchTerm = '') =>
		updateSearch({ searchTerm, page: 1 } as Partial<T>);
	const updatePage = (page: number) => updateSearch({ page } as Partial<T>);
	const updateOrderBy = (field?: T['field'], direction?: ORDER_DIRECTION) =>
		updateSearch({ field, direction } as Partial<T>);

	const addSearchFilter = <K extends keyof T>({
		key,
		value
	}: Omit<OnFilterChangeArgs<T, K>, 'search'>) => {
		const partialFilters = addFilter({ key, value, search });
		addAppliedFilter({ key, value, search });
		updateSearch({ ...partialFilters, page: 1 });
	};

	const removeSearchFilter = <K extends keyof T>({
		key,
		value
	}: Omit<OnFilterChangeArgs<T, K>, 'search'>) => {
		const partialFilters = removeFilter({ key, value, search });
		removeAppliedFilter({ key, value, search });
		updateSearch({ ...partialFilters, page: 1 });
	};

	const clearSearchFilters = () => {
		const partialFilters = clearFilters(search);
		clearAppliedFilter();
		updateSearch(partialFilters);
	};

	return {
		search,
		appliedFilters,
		isFilterApplied,
		updateInputSearch,
		removeSearchFilter,
		addSearchFilter,
		clearSearchFilters,
		updatePage,
		updateOrderBy
	};
};
