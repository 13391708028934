import { PropsWithChildren, ReactElement, useState } from 'react';

import styled from '@emotion/styled';

import { IconExpandMore } from '@agentero/icons';

import { Text } from './Typography';
import { TerciaryButton } from './button/TerciaryButton';

const AccordionContainer = styled.div`
	padding: ${({ theme }) => theme.sizes.getSize(2)};
	border: 1px solid ${({ theme }) => theme.colors.gray.lighten90};

	${({ theme }) => theme.mediaqueries.M} {
		padding: 24px;
	}
`;

const AccordionHeader = styled.div`
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const AccordionContent = styled.div`
	padding-top: ${({ theme }) => theme.sizes.getSize(2)};

	${({ theme }) => theme.mediaqueries.M} {
		padding-top: ${({ theme }) => theme.sizes.getSize(3)};
	}
`;

type AccordionProps = {
	title: ReactElement | string;
	initialOpen?: boolean;
	'data-cy'?: string;
};

export const Accordion = ({
	title,
	initialOpen = false,
	children,
	'data-cy': dataCy
}: PropsWithChildren<AccordionProps>) => {
	const [isOpen, setIsOpen] = useState(initialOpen);

	const handleAccordionClick = () => {
		setIsOpen(!isOpen);
	};

	return (
		<AccordionContainer data-cy={dataCy}>
			<AccordionHeader onClick={handleAccordionClick}>
				<Text>
					<b>{title}</b>
				</Text>
				<TerciaryButton>
					<IconExpandMore css={{ transform: isOpen && 'scaleY(-1)' }} />
				</TerciaryButton>
			</AccordionHeader>
			{isOpen && <AccordionContent>{children}</AccordionContent>}
		</AccordionContainer>
	);
};
