import { PropsWithChildren } from 'react';

import styled from '@emotion/styled';
import { DialogContent, DialogOverlay } from '@reach/dialog';

import { IconClose } from '@agentero/icons';

import { TerciaryButton } from './button/TerciaryButton';

type ModalSize = 'md' | 'lg' | 'xl';

type ModalProps = {
	isOpen: boolean;
	onDismiss: () => void;
	className?: string;
	size?: ModalSize;
};

//TODO: Back-ag -> z-index should have 1 but we need 1001 to work in back-ag
export const ModalOverlay = styled(DialogOverlay)`
	${({ theme }) => theme.overlay.main}
	position: fixed;
	/* TODO: fix z-index */
	z-index: 1006;
	top: -32px;
	right: -32px;
	bottom: -32px;
	left: -32px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: auto;
`;

const size = {
	md: '400px',
	lg: '600px',
	xl: '800px'
};

const ModalInner = styled(DialogContent)<{ size: ModalSize }>`
	position: relative;
	background: ${({ theme }) => theme.colors.white};
	width: calc(100% - 88px);
	max-width: 400px;
	display: flex;
	flex-direction: column;
	outline: none;
	max-height: 80vh;
	will-change: transform;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: ${({ theme }) => theme.shadows.medium};

	${({ theme }) => theme.mediaqueries.M} {
		width: ${props => size[props.size]};
		max-width: 100%;
		max-height: calc(100vh - 80px);
		flex-direction: row;
	}
`;

export const ModalActions = styled.div`
	position: absolute;
	z-index: 2;
	top: 24px;
	right: 24px;
`;

export const Modal = ({
	isOpen,
	onDismiss,
	children,
	className,
	size = 'lg'
}: PropsWithChildren<ModalProps>) => (
	<ModalOverlay isOpen={isOpen} style={{}} onDismiss={onDismiss} as="div">
		<ModalInner className={className} as="div" size={size}>
			<ModalActions>
				<TerciaryButton onClick={onDismiss}>
					<IconClose />
				</TerciaryButton>
			</ModalActions>
			{children}
		</ModalInner>
	</ModalOverlay>
);
