import { Content, List, Root, Trigger } from '@radix-ui/react-tabs';

import { sva } from '@agentero/styles/css';
import { createStyleContext } from '@agentero/styles/tools';

const tabsStyles = sva({
	slots: ['root', 'list', 'trigger', 'content'],
	base: {
		root: {
			display: 'flex',
			flexDirection: 'column'
		},
		list: {
			flexShrink: 0,
			display: 'flex',
			overflow: 'auto hidden',
			WebkitOverflowScrolling: 'touch',
			zIndex: 1
		},
		trigger: {
			position: 'relative',
			textStyle: 'body.small',
			color: 'neutral.60',
			cursor: 'pointer',
			textDecoration: 'none',
			fontWeight: '600',
			_hover: {
				color: 'neutral'
			},
			_focusVisible: {
				outline: 0
			},
			'&[data-state="active"]': {
				backgroundColor: 'white',
				color: 'neutral'
			},
			transition: '0.15s'
		}
	},
	variants: {
		variant: {
			line: {
				list: {
					gap: '24',
					paddingInline: '16',
					width: '100%',
					borderBottomWidth: '0.0625rem',
					borderBottomStyle: 'solid',
					borderBottomColor: 'border',
					backgroundColor: 'bg'
				},
				trigger: {
					display: 'grid',
					alignContent: 'center',
					height: '2.5rem',
					_before: {
						content: '""',
						position: 'absolute',
						bottom: 0,
						left: 0,
						right: 0,
						height: '0.25rem',
						background: 'neutral',
						transform: 'scaleY(0)',
						transformOrigin: 'center bottom',
						transition: '0.2s',
						willChange: 'transform'
					},
					'&[data-state="active"]': {
						_before: {
							transform: 'scaleY(1)'
						}
					}
				}
			},
			enclosed: {
				list: {
					display: 'inline-grid',
					gridAutoColumns: '1fr',
					maxWidth: '100%',
					gridAutoFlow: 'column',
					padding: '4',
					backgroundColor: 'neutral.95',
					borderRadius: 'sm'
				},
				trigger: {
					paddingBlock: '4',
					paddingInline: '12',
					borderRadius: 'xs',
					textAlign: 'center'
				}
			}
		},
		position: {
			center: {
				list: {
					alignSelf: 'center',
					justifyContent: 'center'
				}
			},
			start: {
				list: {
					alignSelf: 'flex-start'
				}
			},
			end: {
				list: {
					alignSelf: 'flex-end',
					justifyContent: 'flex-end'
				}
			}
		},
		// NOTE: "full" variant is intended to be used in full pages layout instead the deprecated Tabs component from @agentero/components.
		// TODO: remove "full" variant when deprecated Tabs are removed and the new layout is implemented.
		full: {
			true: {
				root: {
					flex: 1,
					minHeight: 0,
					overflow: 'hidden auto',
					WebkitOverflowScrolling: 'touch',
					marginBottom: -16
				},
				list: {
					position: 'sticky',
					top: 0
				},
				content: {
					display: 'flex',
					flex: 1,
					flexDirection: 'column'
				}
			}
		}
	},
	defaultVariants: {
		variant: 'line',
		position: 'start'
	}
});

const { withProvider, withContext } = createStyleContext(tabsStyles);

export const Tabs = {
	Root: withProvider(Root, 'root'),
	List: withContext(List, 'list'),
	Trigger: withContext(Trigger, 'trigger'),
	Content: withContext(Content, 'content')
};
