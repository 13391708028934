import React, { forwardRef, useState } from 'react';

import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';

import { IconClose, IconSearch } from '@agentero/icons';

import { TerciaryButton } from '../button/TerciaryButton';
import { Input, InputProps } from './Input';

type InputCustomProps = Omit<
	React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
	'onChange'
> & {
	onChange?: (newValue: string) => void;
};

export type Values = {
	inputValue?: string;
	outputValue?: string;
};

export type InputSearchProps = InputCustomProps & InputProps;

const InputSearchContainer = styled.div`
	position: relative;
`;

const InputTextSearch = styled(Input)`
	padding-right: ${({ isBig }) => (isBig ? '46px' : '32px')};

	&:focus {
		padding-right: ${({ isBig }) => (isBig ? '46px' : '32px')};
	}

	+ svg {
		margin: ${({ isBig }) => (isBig ? '8px' : '4px')};
	}
`;

const IconInputTextSearch = styled(IconSearch)`
	position: absolute;
	top: 0;
	right: 0;
	width: 24px;
	height: 24px;
`;

const InputSearchClose = styled(TerciaryButton)`
	position: absolute;
	top: 4px;
	right: 4px;
	width: 24px;
	height: 24px;
	padding: 2px;

	svg {
		width: 100%;
		height: 100%;
		margin: 0;
	}
`;

export const InputSearchText = forwardRef<HTMLInputElement, InputSearchProps>(
	({ isBig, onChange, placeholder, ...props }, ref) => {
		const { t } = useTranslation('inputSearch');
		const [values, setValues] = useState<Values>({ inputValue: '', outputValue: undefined });

		if (props.value !== values?.outputValue) {
			setValues({ inputValue: props.value?.toString(), outputValue: props.value?.toString() });
		}

		const onSearchChange = (value: string) => {
			setValues(values => ({ ...values, inputValue: value }));
			onChange?.(value);
		};

		return (
			<InputSearchContainer>
				<InputTextSearch
					{...props}
					placeholder={placeholder ? placeholder : t('search')}
					type="text"
					value={values?.inputValue}
					onChange={event => onSearchChange(event.target.value)}
					isBig={isBig}
					ref={ref}
				/>
				{values?.inputValue && !props.disabled ? (
					<InputSearchClose type="button" onClick={() => onSearchChange('')}>
						<IconClose />
					</InputSearchClose>
				) : (
					<IconInputTextSearch />
				)}
			</InputSearchContainer>
		);
	}
);
