import { CurrentTier, PaymentState } from '@agentero/grpc-clients/grpc/shared/tier';
import { getDateFromGrpc } from '@agentero/utils';

import { Tier, parseTier } from '../tier';
import {
	Campaign,
	isIncludedInTwoPoliciesMonths,
	parseCampaignEnum
} from './agentSubscription/Campaign';

export type AgentSubscription = {
	tier: Tier;
	isTrial: boolean;
	trialDaysLeft: number;
	hasFirstPolicySold: boolean;
	isBlocked: boolean;
	isActive: boolean;
	activeAt?: Date;
	campaign?: Campaign;
	isPaymentFailed?: boolean;
	isBasedOnPif: boolean;
};

export const isAllowedToGetAppointed = (subscription: AgentSubscription): boolean => {
	return (
		subscription.tier !== Tier.Bronze ||
		(!!subscription.campaign && isIncludedInTwoPoliciesMonths(subscription.campaign))
	);
};

const parseSubscriptionBlocked = (currentTier?: CurrentTier.AsObject): boolean => {
	return currentTier
		? currentTier.isTrial && currentTier.trialDaysLeft <= 0 && !currentTier.hasFirstPolicySold
		: false;
};

export const parseAgentSubscription = (
	currentTier: CurrentTier.AsObject | undefined
): AgentSubscription => {
	const suscriptionMigrationActive = currentTier
		? getDateFromGrpc(currentTier?.migrationActiveAt)
		: undefined;

	return {
		hasFirstPolicySold: !!currentTier?.hasFirstPolicySold,
		trialDaysLeft: currentTier?.trialDaysLeft || 0,
		isTrial: !!currentTier?.isTrial,
		tier: parseTier(currentTier?.tier),
		isBlocked: parseSubscriptionBlocked(currentTier),
		isActive: !suscriptionMigrationActive || suscriptionMigrationActive < new Date(),
		activeAt: suscriptionMigrationActive,
		campaign: currentTier?.campaignsList?.length
			? parseCampaignEnum(currentTier?.campaignsList[0])
			: undefined,
		isPaymentFailed: currentTier?.paymentState === PaymentState.PAYMENT_STATE_FAILED,
		isBasedOnPif: !!currentTier?.isBasedOnPif
	};
};
