import styled from '@emotion/styled';

import { Button } from '../Button';

/**
 * @deprecated
 */
export const PrimaryButton = styled(Button)`
	color: ${props => props.theme.colors.white};
	background: var(--colors-button-bg-primary);
	border-color: ${props => props.theme.colors.gray.base};

	svg {
		circle,
		path:last-of-type {
			fill: ${props => props.theme.colors.white};
		}
	}

	&:visited {
		color: ${props => props.theme.colors.white};
	}

	@media (hover: hover) and (pointer: fine) {
		&:hover,
		&:focus {
			color: ${props => props.theme.colors.white};
			background: ${props => props.theme.colors.gray.lighten20};
			border-color: ${props => props.theme.colors.gray.lighten20};
		}
	}

	&:active {
		color: ${props => props.theme.colors.white};
		background: ${props => props.theme.colors.gray.lighten40};
		border-color: ${props => props.theme.colors.gray.lighten40};
	}
`;

/**
 * @deprecated
 */
export const PrimaryButtonDark = styled(PrimaryButton)``;

/**
 * @deprecated
 */
export const PrimaryButtonLink = PrimaryButton.withComponent('a');
