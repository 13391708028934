import React from 'react';

import styled from '@emotion/styled';

import { IconClose } from '@agentero/icons';

import { TerciaryButton } from '../button/TerciaryButton';

interface ISidebarCloseButton {
	onClose: () => void;
}

const SidebarCloseButtonContainer = styled(TerciaryButton)`
	position: absolute;
	z-index: 2;
	top: 16px;
	right: 16px;
`;

export const SidebarCloseButton: React.FC<ISidebarCloseButton> = ({ onClose }) => (
	<SidebarCloseButtonContainer onClick={onClose} data-cy="sidebar-close-button">
		<IconClose />
	</SidebarCloseButtonContainer>
);
