import { CurrentTier, Campaign as ProtoCampaign } from '@agentero/grpc-clients/grpc/shared/tier';

export enum Campaign {
	CAMPAIGN_MORE_TWO_POLICIES_MONTH = 'campaign-more-two-policies-month',
	CAMPAIGN_LESS_TWO_POLICIES_MONTH = 'campaign-less-two-policies-month',
	CAMPAIGN_TWO_MONTHS_GRACE = 'campaign-two-months-grace'
}

const campaignTwoPoliciesMonths = [
	Campaign.CAMPAIGN_MORE_TWO_POLICIES_MONTH,
	Campaign.CAMPAIGN_LESS_TWO_POLICIES_MONTH
];

export const isIncludedInTwoPoliciesMonths = (campaign: Campaign): boolean =>
	campaignTwoPoliciesMonths.includes(campaign);

const map = (goalReached: boolean) => {
	return {
		[ProtoCampaign.CAMPAIGN_UNSPECIFIED]: undefined,
		[ProtoCampaign.CAMPAIGN_TWO_POLICIES_MONTH]: goalReached
			? Campaign.CAMPAIGN_MORE_TWO_POLICIES_MONTH
			: Campaign.CAMPAIGN_LESS_TWO_POLICIES_MONTH,
		[ProtoCampaign.CAMPAIGN_TWO_MONTHS_GRACE]: Campaign.CAMPAIGN_TWO_MONTHS_GRACE
	};
};

export const parseCampaignEnum = (
	value?: CurrentTier.CampaignInfo.AsObject
): Campaign | undefined => {
	return value ? map(!!value.goalReached)[value.name] : undefined;
};
