import { ReactNode } from 'react';

import { Arrow, Content, Portal, Provider, Root, Trigger } from '@radix-ui/react-tooltip';

import { styled } from '@agentero/styles/jsx';

type TooltipProps = {
	children: ReactNode;
	content: ReactNode;
};

const TooltipTrigger = styled(Trigger, {
	base: {
		verticalAlign: 'middle'
	}
});

const TooltipContent = styled(Content, {
	base: {
		zIndex: 999999,
		maxWidth: '24ch',
		paddingBlock: 10,
		paddingInline: 20,
		textStyle: 'body.small',
		color: 'white',
		background: 'neutral',
		userSelect: 'none',

		'& a': {
			color: 'white',
			textDecoration: 'underline',

			'&:hover, &:focus': {
				color: 'neutral.90'
			},

			'&:active': {
				color: 'neutral.80'
			}
		}
	}
});

const TooltipArrow = styled(Arrow, {
	base: {
		fill: 'neutral'
	}
});

export const Tooltip = ({ children, content }: TooltipProps) => {
	return (
		<>
			<Provider delayDuration={0} skipDelayDuration={0}>
				<Root>
					<TooltipTrigger asChild>
						<button type="button" data-testid="tooltip-trigger">
							{children}
						</button>
					</TooltipTrigger>
					<Portal>
						<TooltipContent sideOffset={4} role="tooltip">
							{content}
							<TooltipArrow />
						</TooltipContent>
					</Portal>
				</Root>
			</Provider>
		</>
	);
};
