import { PropsWithChildren, useRef, useState } from 'react';

import styled from '@emotion/styled';

import { Text } from '@agentero/components';
import { useSSRLayoutEffect } from '@agentero/hooks';

type ExpanderParentProps = {
	maxHeight: number;
	isExpanded?: boolean;
};

const ExpanderParent = styled.div<ExpanderParentProps>`
	position: relative;
	max-height: ${({ maxHeight, isExpanded = false }) => (isExpanded ? 'inherit' : `${maxHeight}px`)};
	overflow: hidden;
`;

const ExpanderShadow = styled.div`
	position: absolute;
	bottom: 0;
	height: 16px;
	width: 100%;
	background: linear-gradient(
		0deg,
		${({ theme }) => theme.colors.white} 0%,
		rgba(255, 255, 255, 0) 100%
	);
`;

type ExpanderProps = {
	maxHeight: number;
	hasShadow?: boolean;
};

type ExpanderState = {
	isTogglable: boolean;
	isExpanded: boolean;
};

export const Expander = ({
	maxHeight,
	children,
	hasShadow = true
}: PropsWithChildren<ExpanderProps>) => {
	const [state, setState] = useState<ExpanderState>({
		isExpanded: false,
		isTogglable: false
	});
	const child = useRef<HTMLDivElement>(null);

	const onToggle = () => setState(state => ({ ...state, isExpanded: !state.isExpanded }));

	useSSRLayoutEffect(() => {
		if (child.current) {
			const { height } = child.current.getBoundingClientRect();

			if (height > maxHeight) {
				setState(state => ({ ...state, isTogglable: true }));
			}
		}
	}, []);

	return (
		<div>
			<ExpanderParent maxHeight={maxHeight} isExpanded={state.isExpanded}>
				<div ref={child}>{children}</div>
				{state.isTogglable && !state.isExpanded && hasShadow && <ExpanderShadow role="separator" />}
			</ExpanderParent>
			{state.isTogglable && (
				<Text size="S" mt={8}>
					<a data-cy="expander-button" onClick={onToggle}>
						<b>{state.isExpanded ? 'See less' : 'See more'}</b>
					</a>
				</Text>
			)}
		</div>
	);
};
