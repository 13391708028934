import styled from '@emotion/styled';

import { SecondaryButton } from './SecondaryButton';

/**
 * @deprecated
 */
export const PrimaryGhostButton = styled(SecondaryButton)``;

/**
 * @deprecated
 */
export const PrimaryGhostButtonLink = PrimaryGhostButton.withComponent('a');
