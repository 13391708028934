import { removeSuperAdminToken } from '@agentero/utils';

import { AgentAgency, parseAgentAgency } from './agent/AgentAgency';
import { AgentCompliance, parseAgentCompliance } from './agent/AgentCompliance';
import { AgentSession } from './agent/AgentSession';
import { AgentSubscription } from './agent/AgentSubscription';
import { CurrentExpertResponse } from './agent/CarrierSubscription';
import { Role, parseRole } from './agent/Role';

export type Agent = {
	id: string;
	token: string;
	backAgToken: string;
	sessionCount: number;
	email: string;
	firstName: string;
	lastName: string;
	photoUrl: string;
	isImpersonation: boolean;
	role: Role;
	isContractAccepted: boolean;
	agency: AgentAgency;
	phoneNumber: string;
	isCommercialRaterEnabled: boolean;
	appointmentsEnabled: boolean;
	compliance: AgentCompliance;
	subscription?: AgentSubscription;
	communicationsPreferences?: {
		email?: boolean;
		sms?: boolean;
	};
	hasAppointedTerminated: boolean;
};

export const parseAgent = (
	currentExpert: CurrentExpertResponse,
	agentSession?: AgentSession
): Agent => {
	const role = parseRole(currentExpert.attributes.agencyAdmin);

	return {
		id: currentExpert.id,
		token: currentExpert.attributes.authenticationToken,
		backAgToken: removeSuperAdminToken(currentExpert.attributes.authenticationToken),
		sessionCount: currentExpert.attributes.sessionCount ?? 0,
		email: currentExpert.attributes.email,
		firstName: currentExpert.attributes.firstName,
		lastName: currentExpert.attributes.lastName,
		photoUrl: currentExpert.attributes.photoUrl ?? '',
		isImpersonation: currentExpert.attributes.isImpersonation || false,
		role,
		isContractAccepted:
			role === Role.SuperAdmin ? true : currentExpert.attributes.isContractAccepted || false,
		agency: parseAgentAgency(currentExpert, agentSession),
		phoneNumber: currentExpert.attributes.phoneNumber,
		isCommercialRaterEnabled: Boolean(agentSession?.isCommercialRaterEnabled),
		appointmentsEnabled: Boolean(agentSession?.appointmentsEnabled),
		compliance: parseAgentCompliance(agentSession),
		subscription: agentSession?.subscription,
		communicationsPreferences: agentSession?.communicationsPreferences,
		hasAppointedTerminated: !!agentSession?.hasAppointedTerminated
	};
};

export const isAgent = (agent: Agent) =>
	agent && !agent.isImpersonation && agent.role !== Role.SuperAdmin;
