import { LineOfInsurance as LineOfInsuranceProto } from '@agentero/grpc-clients/grpc/shared/policy';

export enum LineOfInsurance {
	Personal = 'personal',
	Commercial = 'commercial',
	Life = 'life'
}

const map: { [key in LineOfInsurance]: LineOfInsuranceProto } = {
	[LineOfInsurance.Personal]: LineOfInsuranceProto.LINE_OF_INSURANCE_PERSONAL,
	[LineOfInsurance.Life]: LineOfInsuranceProto.LINE_OF_INSURANCE_LIFE,
	[LineOfInsurance.Commercial]: LineOfInsuranceProto.LINE_OF_INSURANCE_COMMERCIAL
};

export const parseLineOfInsuranceToProto = (value: LineOfInsurance): LineOfInsuranceProto =>
	map[value];
