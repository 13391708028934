import { PropsWithChildren } from 'react';

import { getFieldError, getFieldErrorId, removeAllDots, useAgFormContext } from '@agentero/ui';
import { getInputStatusFromError } from '@agentero/utils';

import { Field, FieldGenericProps } from '../shared/Field';
import { InputDate } from './fieldDate/InputDate';
import { InputVariants } from './fieldText/Input';

type FieldDateProps<T> = FieldGenericProps<T> & {
	defaultValue?: string;
} & InputVariants;

export const FieldDate = <T extends {}>({
	name,
	defaultValue,
	children,
	size,
	disabled,
	...formGroupProps
}: PropsWithChildren<FieldDateProps<T>>) => {
	const {
		register,
		formState: { errors }
	} = useAgFormContext<T>();

	const fieldError = getFieldError(errors, name);

	return (
		<Field name={name} nestedChildren={children} {...formGroupProps}>
			<InputDate
				id={removeAllDots(name)}
				{...register(name)}
				placeholder="MM/DD/YYYY"
				status={getInputStatusFromError(fieldError?.message as string)}
				aria-invalid={!!fieldError?.message}
				aria-errormessage={getFieldErrorId(name)}
				defaultValue={defaultValue}
				size={size}
				disabled={disabled}
			/>
		</Field>
	);
};
