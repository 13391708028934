//TODO: Remove when https://github.com/tannerlinsley/@tanstack/react-query/issues/1503 is fixed
import { Suspense } from 'react';

// eslint-disable-next-line @emotion/no-vanilla
import { EmotionCache } from '@emotion/react';
import { DehydratedState } from '@tanstack/react-query';
import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { Agent, isAgencyAdmin, isAgent } from '@agentero/models';
import { createAgenteroCache } from '@agentero/styles/emotion';
import '@agentero/styles/global.css';

import { Providers } from 'modules/providers';
import { BindWithAgenteroTrainingFirstLoginModal } from 'modules/shared/BindWithAgenteroTrainingFirstLoginModal';
import { URLProvider } from 'modules/shared/UrlContext';
import { TierModalPaymentFailed } from 'modules/tiersAndBilling/TierModalPaymentFailed';
import { AppcuesIdentify } from 'packages/analytics/AppcuesIdentify';
import { HubspotChat } from 'packages/analytics/HubspotChat';
import { AgentUniqueIdentifierContextProvider } from 'packages/contexts/AgentUniqueIdentifierContext';

//@ts-ignore
global.XMLHttpRequest = require('xhr2');

//@ts-ignore
if (typeof global.navigator === 'undefined') global.navigator = {}; //TODO: Remove when https://github.com/tannerlinsley/@tanstack/react-query/issues/1503 is fixed

type AgenteroAppProps = {
	agent: Agent;
	agentUniqueIdentifier: string;
	dehydratedState: DehydratedState;
};

type Props = NextPage<AppProps<AgenteroAppProps> & { emotionCache: EmotionCache }, {}>;

const clientSideCache = createAgenteroCache();

const AgenteroApp: Props = props => {
	const { Component, pageProps, emotionCache = clientSideCache } = props;
	const { agent, agentUniqueIdentifier } = pageProps;
	const { query } = useRouter();

	return (
		<AgentUniqueIdentifierContextProvider agentUniqueIdentifier={agentUniqueIdentifier}>
			<Head>
				<title>Agentero App</title>
			</Head>
			<Providers dehydratedState={pageProps.dehydratedState} emotionCache={emotionCache}>
				<URLProvider>
					<Suspense fallback="Loading...">
						<Component {...pageProps} />
						{agent && <TierModalPaymentFailed />}
						{agent && isAgencyAdmin(agent.role) && (
							<Suspense fallback={null}>
								<BindWithAgenteroTrainingFirstLoginModal />
							</Suspense>
						)}
					</Suspense>
				</URLProvider>
				{process.env.NEXT_PUBLIC_USE_THIRD_PARTY_SCRIPTS === 'true' && agent && isAgent(agent) && (
					<Suspense fallback={null}>
						{!query.binderId && <HubspotChat />}
						<AppcuesIdentify />
					</Suspense>
				)}
			</Providers>
		</AgentUniqueIdentifierContextProvider>
	);
};

export default AgenteroApp;
