import styled from '@emotion/styled';

import { Button } from '../Button';

/**
 * @deprecated
 */
export const SecondaryButton = styled(Button)`
	color: ${props => props.theme.colors.gray.base};
	background: ${props => props.theme.colors.white};
	border-color: ${props => props.theme.colors.gray.base};

	svg {
		circle,
		path:last-of-type {
			fill: ${props => props.theme.colors.gray.base};
		}
	}

	@media (hover: hover) and (pointer: fine) {
		&:focus,
		&:hover {
			color: ${props => props.theme.colors.gray.base};
			background: ${props => props.theme.colors.gray.lighten95Alpha};

			svg {
				circle,
				path:last-of-type {
					fill: ${props => props.theme.colors.gray.base};
				}
			}
		}
	}

	&:visited {
		color: ${props => props.theme.colors.gray.lighten40};
	}

	&:active {
		color: ${props => props.theme.colors.gray.base};
		background: ${props => props.theme.colors.gray.lighten90Alpha};

		svg {
			circle,
			path:last-of-type {
				fill: ${props => props.theme.colors.gray.base};
			}
		}
	}
`;

/**
 * @deprecated
 */
export const SecondaryButtonLink = SecondaryButton.withComponent('a');
