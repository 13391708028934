import { InsuranceType as InsuranceTypeShared } from '@agentero/grpc-clients/grpc/shared/policy';
import { CommercialIcon, HomeOwnersIcon, PersonalAutoIcon, PublicIcon } from '@agentero/icons';

export enum InsuranceType {
	UndefinedInsuranceType = 'public',
	PersonalAuto = 'personal_auto',
	PersonalUmbrella = 'personal_umbrella',
	Homeonwners = 'home_owner',
	Renter = 'renter',
	Condo = 'condo',
	DwellingFire = 'dwelling_fire',
	Landlord = 'landlord',
	Boat = 'boat',
	Motorcycle = 'motorcycle',
	Flood = 'flood',
	AtvOffRoadVehicle = 'atv_off_road_vehicle',
	Medicare = 'medicare',
	Pet = 'pet',
	Snowmobile = 'snowmobile',
	PersonalWind = 'personal_wind',
	Health = 'health',
	Earthquake = 'earthquake',
	Vision = 'vision',
	ScheduledPersonalProperty = 'scheduled_personal_property',
	AntiqueClassicAuto = 'antique_classic_auto',
	RvMotorhome = 'rv_motorhome',
	Dental = 'dental',
	EventLiability = 'event_liability',
	IdentityProtection = 'identity_protection',
	MobileHomeowners = 'mobile_homeowners',
	Life = 'life',
	ElectronicDevices = 'electronic_devices',
	Ridesharing = 'ridesharing',
	Travel = 'travel',
	Other = 'other',
	CommercialAuto = 'commercial_auto',
	Commercial = 'commercial',
	InlandMarine = 'inland_marine',
	Multiline = 'multiline',
	ProfessionalLiability = 'professional_liability',
	GeneralLiability = 'general_liability',
	BusinessOwnerPolicy = 'business_owner_policy',
	WorkersCompesation = 'workers_compesation',
	CommercialProperty = 'commercial_property',
	CommercialUmbrella = 'commercial_umbrela',
	DirectorsAndOfficers = 'directors_and_officers',
	EmploymentPracticesLiability = 'employment_practices_liability',
	ErrorsAndOmissions = 'errors_and_omissions',
	CommercialPackage = 'commercial_package',
	LiquorLiability = 'liquor_liability',
	ExcessLiability = 'excess_liability',
	CommercialInlandMarine = 'commercial_inland_marine',
	OceanMarine = 'ocean_marine',
	PolutionLiability = 'polution_liability',
	Bond = 'bond',
	BondFidelity = 'bond_fidelity',
	BondFiduciary = 'bond_fiduciary',
	BondSurety = 'bond_surety',
	Crime = 'crime',
	CyberLiability = 'cyber_liability',
	GarageAndDealers = 'garage_and_dealers',
	GarageKeepersLegalLiability = 'garage_keepers_legal_liability',
	InstallationBuildersRisk = 'installation_builders_risk',
	ShortTermDisability = 'short_term_liability',
	EmployeeBenefitsLiability = 'employee_benefits_liability',
	EquipmentBreakdown = 'equipment_breakdown',
	BusinessInterruption = 'business_interruption',
	HiredAndNonOwnedAuto = 'hired_and_non_owned_auto',
	MedicalMalpractice = 'medical_malpractice',
	ProductLiability = 'product_liability',
	FiduciaryLiability = 'fiduciary_liability',
	Habitational = 'habitational',
	OwnersAndContractorsProtectiveLiability = 'owners_and_contractors_protective_liability',
	FireBoilerAndMachinery = 'fire_boiler_and_machinery',
	RentGuarantee = 'rent_guarantee',
	PersonalPackage = 'personal_package',
	CommercialFleet = 'commercial_fleet',
	CommercialWholesaleBrokers = 'commercial_wholesale_brokers',
	ExcessAndSurplusCommercialCasualty = 'excess_and_surplus_commercial_casualty',
	Powersports = 'powersports',
	CollectorsVehicle = 'collectors_vehicle',
	Yacht = 'yacht',
	VacantHomeowners = 'vacant_homeowners',
	TravelTrailer = 'travel_trailer',
	Watercraft = 'watercraft',
	OffRoadVehicle = 'off_road_vehicle',
	ManufacturedHome = 'manufactured_home',
	SpecialEvents = 'special_events',
	SpecialtyCommercial = 'specialty_commercial',
	ResidentialFlood = 'residential_flood',
	CommercialFlood = 'commercial_flood',
	ParametricEarthquake = 'parametric_earthquake',
	HomeAndElectronicProtection = 'home_and_electronic_proyection',
	RecreationalVehicles = 'recreational_vehicles'
}

export type InsuranceTypeFromBackAg = {
	id: string;
	type: InsuranceType;
};

export const raterInsuranceTypeMapping = {
	homeowners: InsuranceType.Homeonwners,
	auto: InsuranceType.PersonalAuto
};

export const insuranceTypeFromServiceProtoMapping: { [key in InsuranceTypeShared]: InsuranceType } =
	{
		[InsuranceTypeShared.UNDEFINED_INSURANCE_TYPE]: InsuranceType.UndefinedInsuranceType,
		[InsuranceTypeShared.ANTIQUE_CLASSIC_AUTO]: InsuranceType.AntiqueClassicAuto,
		[InsuranceTypeShared.ATV_OFF_ROAD_VEHICLE]: InsuranceType.AtvOffRoadVehicle,
		[InsuranceTypeShared.BOAT]: InsuranceType.Boat,
		[InsuranceTypeShared.COMMERCIAL_AUTO]: InsuranceType.CommercialAuto,
		[InsuranceTypeShared.CONDO]: InsuranceType.Condo,
		[InsuranceTypeShared.DENTAL]: InsuranceType.Dental,
		[InsuranceTypeShared.DWELLING_FIRE]: InsuranceType.DwellingFire,
		[InsuranceTypeShared.EARTHQUAKE]: InsuranceType.Earthquake,
		[InsuranceTypeShared.ELECTRONIC_DEVICES]: InsuranceType.ElectronicDevices,
		[InsuranceTypeShared.EVENT_LIABILITY]: InsuranceType.EventLiability,
		[InsuranceTypeShared.FLOOD]: InsuranceType.Flood,
		[InsuranceTypeShared.HEALTH]: InsuranceType.Health,
		[InsuranceTypeShared.HOMEOWNERS]: InsuranceType.Homeonwners,
		[InsuranceTypeShared.IDENTITY_PROTECTION]: InsuranceType.IdentityProtection,
		[InsuranceTypeShared.LANDLORD]: InsuranceType.Landlord,
		[InsuranceTypeShared.LIFE]: InsuranceType.Life,
		[InsuranceTypeShared.MEDICARE]: InsuranceType.Medicare,
		[InsuranceTypeShared.MOBILE_HOMEOWNERS]: InsuranceType.MobileHomeowners,
		[InsuranceTypeShared.MOTORCYCLE]: InsuranceType.Motorcycle,
		[InsuranceTypeShared.OTHER]: InsuranceType.Other,
		[InsuranceTypeShared.PERSONAL_AUTO]: InsuranceType.PersonalAuto,
		[InsuranceTypeShared.PERSONAL_UMBRELLA]: InsuranceType.PersonalUmbrella,
		[InsuranceTypeShared.PERSONAL_WIND]: InsuranceType.PersonalWind,
		[InsuranceTypeShared.PET]: InsuranceType.Pet,
		[InsuranceTypeShared.RENTERS]: InsuranceType.Renter,
		[InsuranceTypeShared.RIDESHARING]: InsuranceType.Ridesharing,
		[InsuranceTypeShared.RV_MOTORHOME]: InsuranceType.RvMotorhome,
		[InsuranceTypeShared.SCHEDULED_PERSONAL_PROPERTY]: InsuranceType.ScheduledPersonalProperty,
		[InsuranceTypeShared.SNOWMOBILE]: InsuranceType.Snowmobile,
		[InsuranceTypeShared.TRAVEL]: InsuranceType.Travel,
		[InsuranceTypeShared.VISION]: InsuranceType.Vision,
		[InsuranceTypeShared.COMMERCIAL]: InsuranceType.Commercial,
		[InsuranceTypeShared.INLAND_MARINE]: InsuranceType.InlandMarine,
		[InsuranceTypeShared.MULTILINE]: InsuranceType.Multiline,
		[InsuranceTypeShared.PROFESSIONAL_LIABILITY]: InsuranceType.ProfessionalLiability,
		[InsuranceTypeShared.GENERAL_LIABILITY]: InsuranceType.GeneralLiability,
		[InsuranceTypeShared.BUSINESS_OWNER_POLICY]: InsuranceType.BusinessOwnerPolicy,
		[InsuranceTypeShared.WORKERS_COMPENSATION]: InsuranceType.WorkersCompesation,
		[InsuranceTypeShared.COMMERCIAL_PROPERTY]: InsuranceType.CommercialProperty,
		[InsuranceTypeShared.COMMERCIAL_UMBRELLA]: InsuranceType.CommercialUmbrella,
		[InsuranceTypeShared.DIRECTORS_AND_OFFICERS]: InsuranceType.DirectorsAndOfficers,
		[InsuranceTypeShared.EMPLOYMENT_PRACTICES_LIABILITY]:
			InsuranceType.EmploymentPracticesLiability,
		[InsuranceTypeShared.ERRORS_AND_OMISSIONS]: InsuranceType.ErrorsAndOmissions,
		[InsuranceTypeShared.COMMERCIAL_PACKAGE]: InsuranceType.CommercialPackage,
		[InsuranceTypeShared.LIQUOR_LIABILITY]: InsuranceType.LiquorLiability,
		[InsuranceTypeShared.EXCESS_LIABILITY]: InsuranceType.ExcessLiability,
		[InsuranceTypeShared.COMMERCIAL_INLAND_MARINE]: InsuranceType.CommercialInlandMarine,
		[InsuranceTypeShared.OCEAN_MARINE]: InsuranceType.OceanMarine,
		[InsuranceTypeShared.POLUTION_LIABILITY]: InsuranceType.PolutionLiability,
		[InsuranceTypeShared.BOND]: InsuranceType.Bond,
		[InsuranceTypeShared.BOND_FIDELITY]: InsuranceType.BondFidelity,
		[InsuranceTypeShared.BOND_FIDUCIARY]: InsuranceType.BondFiduciary,
		[InsuranceTypeShared.BOND_SURETY]: InsuranceType.BondSurety,
		[InsuranceTypeShared.CRIME]: InsuranceType.Crime,
		[InsuranceTypeShared.CYBER_LIABILITY]: InsuranceType.CyberLiability,
		[InsuranceTypeShared.GARAGE_AND_DEALERS]: InsuranceType.GarageAndDealers,
		[InsuranceTypeShared.GARAGE_KEEPERS_LEGAL_LIABILITY]: InsuranceType.GarageKeepersLegalLiability,
		[InsuranceTypeShared.INSTALLATION_BUILDERS_RISK]: InsuranceType.InstallationBuildersRisk,
		[InsuranceTypeShared.SHORT_TERM_DISABILITY]: InsuranceType.ShortTermDisability,
		[InsuranceTypeShared.EMPLOYEE_BENEFITS_LIABILITY]: InsuranceType.EmployeeBenefitsLiability,
		[InsuranceTypeShared.EQUIPMENT_BREAKDOWN]: InsuranceType.EquipmentBreakdown,
		[InsuranceTypeShared.BUSINESS_INTERRUPTION]: InsuranceType.BusinessInterruption,
		[InsuranceTypeShared.HIRED_AND_NON_OWNED_AUTO]: InsuranceType.HiredAndNonOwnedAuto,
		[InsuranceTypeShared.MEDICAL_MALPRACTICE]: InsuranceType.MedicalMalpractice,
		[InsuranceTypeShared.PRODUCT_LIABILITY]: InsuranceType.ProductLiability,
		[InsuranceTypeShared.FIDUCIARY_LIABILITY]: InsuranceType.FiduciaryLiability,
		[InsuranceTypeShared.HABITATIONAL]: InsuranceType.Habitational,
		[InsuranceTypeShared.OWNERS_AND_CONTRACTORS_PROTECTIVE_LIABILITY]:
			InsuranceType.OwnersAndContractorsProtectiveLiability,
		[InsuranceTypeShared.FIRE_BOILER_AND_MACHINERY]: InsuranceType.FireBoilerAndMachinery,
		[InsuranceTypeShared.RENT_GUARANTEE]: InsuranceType.RentGuarantee,
		[InsuranceTypeShared.PERSONAL_PACKAGE]: InsuranceType.PersonalPackage,
		[InsuranceTypeShared.COMMERCIAL_FLEET]: InsuranceType.CommercialFleet,
		[InsuranceTypeShared.COMMERCIAL_WHOLESALE_BROKERS]: InsuranceType.CommercialWholesaleBrokers,
		[InsuranceTypeShared.EXCESS_AND_SURPLUS_COMMERCIAL_CASUALTY]:
			InsuranceType.ExcessAndSurplusCommercialCasualty,
		[InsuranceTypeShared.POWERSPORTS]: InsuranceType.Powersports,
		[InsuranceTypeShared.COLLECTORS_VEHICLE]: InsuranceType.CollectorsVehicle,
		[InsuranceTypeShared.YACHT]: InsuranceType.Yacht,
		[InsuranceTypeShared.VACANT_HOMEOWNERS]: InsuranceType.VacantHomeowners,
		[InsuranceTypeShared.TRAVEL_TRAILER]: InsuranceType.TravelTrailer,
		[InsuranceTypeShared.WATERCRAFT]: InsuranceType.Watercraft,
		[InsuranceTypeShared.OFF_ROAD_VEHICLE]: InsuranceType.OffRoadVehicle,
		[InsuranceTypeShared.MANUFACTURED_HOME]: InsuranceType.ManufacturedHome,
		[InsuranceTypeShared.SPECIAL_EVENTS]: InsuranceType.SpecialEvents,
		[InsuranceTypeShared.SPECIALTY_COMMERCIAL]: InsuranceType.SpecialtyCommercial,
		[InsuranceTypeShared.RESIDENTIAL_FLOOD]: InsuranceType.ResidentialFlood,
		[InsuranceTypeShared.COMMERCIAL_FLOOD]: InsuranceType.CommercialFlood,
		[InsuranceTypeShared.PARAMETRIC_EARTHQUAKE]: InsuranceType.ParametricEarthquake,
		[InsuranceTypeShared.HOME_AND_ELECTRONICS_PROTECTION]:
			InsuranceType.HomeAndElectronicProtection,
		[InsuranceTypeShared.RECREATIONAL_VEHICLES]: InsuranceType.RecreationalVehicles
	};

export const parseInsuranceTypeFromProto = (type: InsuranceTypeShared) => {
	return insuranceTypeFromServiceProtoMapping[type];
};

const insuranceTypeFromBackAgMapping: { [key: string]: InsuranceType } = {
	['Personal Auto']: InsuranceType.PersonalAuto,
	['Motorcycle']: InsuranceType.Motorcycle,
	['Homeowners']: InsuranceType.Homeonwners,
	['Renters']: InsuranceType.Renter,
	['Condo']: InsuranceType.Condo,
	['Health']: InsuranceType.Health,
	['Electronic Devices']: InsuranceType.ElectronicDevices,
	['Pet']: InsuranceType.Pet,
	['ATV/Off-Road Vehicle']: InsuranceType.AtvOffRoadVehicle,
	['Boat']: InsuranceType.Boat,
	['Antique/Classic Auto']: InsuranceType.AntiqueClassicAuto,
	['RV/Motorhome']: InsuranceType.RvMotorhome,
	['Ridesharing']: InsuranceType.Ridesharing,
	['Flood']: InsuranceType.Flood,
	['Landlord']: InsuranceType.Landlord,
	['Dental']: InsuranceType.Dental,
	['Medicare']: InsuranceType.Medicare,
	['Vision']: InsuranceType.Vision,
	['Other']: InsuranceType.Other,
	['Life']: InsuranceType.Life,
	['Snowmobile']: InsuranceType.Snowmobile,
	['Identity Protection']: InsuranceType.IdentityProtection,
	['Travel']: InsuranceType.Travel,
	['Dwelling Fire']: InsuranceType.DwellingFire,
	['Commercial Auto']: InsuranceType.CommercialAuto,
	['Earthquake']: InsuranceType.Earthquake,
	['Event Liability']: InsuranceType.EventLiability,
	['Mobile Homeowners']: InsuranceType.MobileHomeowners,
	['Personal Umbrella']: InsuranceType.PersonalUmbrella,
	['Personal Wind']: InsuranceType.PersonalWind,
	['Scheduled Personal Property']: InsuranceType.ScheduledPersonalProperty,
	['Commercial']: InsuranceType.Commercial,
	['Inland Marine']: InsuranceType.InlandMarine,
	['Multiline']: InsuranceType.Multiline,
	['Professional Liability']: InsuranceType.ProfessionalLiability,
	['General Liability']: InsuranceType.GeneralLiability,
	['Business Owner Policy']: InsuranceType.BusinessOwnerPolicy,
	['Workers Compensation']: InsuranceType.WorkersCompesation,
	['Commercial Propertu']: InsuranceType.CommercialProperty,
	['Commercial Umbrella']: InsuranceType.CommercialUmbrella,
	['Directors and Offices']: InsuranceType.DirectorsAndOfficers,
	['Employment Practices Liability']: InsuranceType.EmploymentPracticesLiability,
	['Errors and Omissions']: InsuranceType.ErrorsAndOmissions,
	['Commercial Package']: InsuranceType.CommercialPackage,
	['Liquor Liability']: InsuranceType.LiquorLiability,
	['Excess Liability']: InsuranceType.ExcessLiability,
	['Commercial Inland Marine']: InsuranceType.CommercialInlandMarine,
	['Ocean Marine']: InsuranceType.OceanMarine,
	['Polution Liability']: InsuranceType.PolutionLiability,
	['Bond']: InsuranceType.Bond,
	['Bond Fidelity']: InsuranceType.BondFidelity,
	['Bond Fiduciary']: InsuranceType.BondFiduciary,
	['Bond Surety']: InsuranceType.BondSurety,
	['Crime']: InsuranceType.Crime,
	['Cyber Liability']: InsuranceType.CyberLiability,
	['Garage and Dealers']: InsuranceType.GarageAndDealers,
	['Garage Keepers Legal Liability']: InsuranceType.GarageKeepersLegalLiability,
	['Installation Builder Risk']: InsuranceType.InstallationBuildersRisk,
	['Short Term Disability']: InsuranceType.ShortTermDisability,
	['Employee Benefits Liability']: InsuranceType.EmployeeBenefitsLiability,
	['Equipment Breakdown']: InsuranceType.EquipmentBreakdown,
	['Business Interruptions']: InsuranceType.BusinessInterruption,
	['Hired and Non-Owned Auto']: InsuranceType.HiredAndNonOwnedAuto,
	['Medical Malpractice']: InsuranceType.MedicalMalpractice,
	['Product Liability']: InsuranceType.ProductLiability,
	['Fiduciary Liability']: InsuranceType.FiduciaryLiability,
	['Habitational']: InsuranceType.Habitational,
	['Owners and Contractors Protective Liability']:
		InsuranceType.OwnersAndContractorsProtectiveLiability,
	['Fire boiler and Machinery']: InsuranceType.FireBoilerAndMachinery,
	['Rent Guarantee']: InsuranceType.RentGuarantee,
	['Personal Package']: InsuranceType.PersonalPackage,
	['Commercial Fleet']: InsuranceType.CommercialFleet,
	['Commercial Wholesale Brokers']: InsuranceType.CommercialWholesaleBrokers
};

export const parseInsuranceTypeFromBackAg = (type: string) => {
	return insuranceTypeFromBackAgMapping[type];
};

const insuranceTypeIconMapping: {
	[key in InsuranceType]?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
} = {
	[InsuranceType.Commercial]: CommercialIcon,
	[InsuranceType.Homeonwners]: HomeOwnersIcon,
	[InsuranceType.PersonalAuto]: PersonalAutoIcon
};

export const getInsuranceTypeIcon = (
	type: InsuranceType
): React.ComponentType<React.SVGProps<SVGSVGElement>> =>
	insuranceTypeIconMapping[type] || PublicIcon;

export const insuranceTypeToServiceProtoMapping: { [key in InsuranceType]: InsuranceTypeShared } = {
	[InsuranceType.UndefinedInsuranceType]: InsuranceTypeShared.UNDEFINED_INSURANCE_TYPE,
	[InsuranceType.AntiqueClassicAuto]: InsuranceTypeShared.ANTIQUE_CLASSIC_AUTO,
	[InsuranceType.AtvOffRoadVehicle]: InsuranceTypeShared.ATV_OFF_ROAD_VEHICLE,
	[InsuranceType.Boat]: InsuranceTypeShared.BOAT,
	[InsuranceType.CommercialAuto]: InsuranceTypeShared.COMMERCIAL_AUTO,
	[InsuranceType.Condo]: InsuranceTypeShared.CONDO,
	[InsuranceType.Dental]: InsuranceTypeShared.DENTAL,
	[InsuranceType.DwellingFire]: InsuranceTypeShared.DWELLING_FIRE,
	[InsuranceType.Earthquake]: InsuranceTypeShared.EARTHQUAKE,
	[InsuranceType.ElectronicDevices]: InsuranceTypeShared.ELECTRONIC_DEVICES,
	[InsuranceType.EventLiability]: InsuranceTypeShared.EVENT_LIABILITY,
	[InsuranceType.Flood]: InsuranceTypeShared.FLOOD,
	[InsuranceType.Health]: InsuranceTypeShared.HEALTH,
	[InsuranceType.Homeonwners]: InsuranceTypeShared.HOMEOWNERS,
	[InsuranceType.IdentityProtection]: InsuranceTypeShared.IDENTITY_PROTECTION,
	[InsuranceType.Landlord]: InsuranceTypeShared.LANDLORD,
	[InsuranceType.Life]: InsuranceTypeShared.LIFE,
	[InsuranceType.Medicare]: InsuranceTypeShared.MEDICARE,
	[InsuranceType.MobileHomeowners]: InsuranceTypeShared.MOBILE_HOMEOWNERS,
	[InsuranceType.Motorcycle]: InsuranceTypeShared.MOTORCYCLE,
	[InsuranceType.Other]: InsuranceTypeShared.OTHER,
	[InsuranceType.PersonalAuto]: InsuranceTypeShared.PERSONAL_AUTO,
	[InsuranceType.PersonalUmbrella]: InsuranceTypeShared.PERSONAL_UMBRELLA,
	[InsuranceType.PersonalWind]: InsuranceTypeShared.PERSONAL_WIND,
	[InsuranceType.Pet]: InsuranceTypeShared.PET,
	[InsuranceType.Renter]: InsuranceTypeShared.RENTERS,
	[InsuranceType.Ridesharing]: InsuranceTypeShared.RIDESHARING,
	[InsuranceType.RvMotorhome]: InsuranceTypeShared.RV_MOTORHOME,
	[InsuranceType.ScheduledPersonalProperty]: InsuranceTypeShared.SCHEDULED_PERSONAL_PROPERTY,
	[InsuranceType.Snowmobile]: InsuranceTypeShared.SNOWMOBILE,
	[InsuranceType.Travel]: InsuranceTypeShared.TRAVEL,
	[InsuranceType.Vision]: InsuranceTypeShared.VISION,
	[InsuranceType.Commercial]: InsuranceTypeShared.COMMERCIAL,
	[InsuranceType.InlandMarine]: InsuranceTypeShared.INLAND_MARINE,
	[InsuranceType.Multiline]: InsuranceTypeShared.MULTILINE,
	[InsuranceType.ProfessionalLiability]: InsuranceTypeShared.PROFESSIONAL_LIABILITY,
	[InsuranceType.GeneralLiability]: InsuranceTypeShared.GENERAL_LIABILITY,
	[InsuranceType.BusinessOwnerPolicy]: InsuranceTypeShared.BUSINESS_OWNER_POLICY,
	[InsuranceType.WorkersCompesation]: InsuranceTypeShared.WORKERS_COMPENSATION,
	[InsuranceType.CommercialProperty]: InsuranceTypeShared.COMMERCIAL_PROPERTY,
	[InsuranceType.CommercialUmbrella]: InsuranceTypeShared.COMMERCIAL_UMBRELLA,
	[InsuranceType.DirectorsAndOfficers]: InsuranceTypeShared.DIRECTORS_AND_OFFICERS,
	[InsuranceType.EmploymentPracticesLiability]: InsuranceTypeShared.EMPLOYMENT_PRACTICES_LIABILITY,
	[InsuranceType.ErrorsAndOmissions]: InsuranceTypeShared.ERRORS_AND_OMISSIONS,
	[InsuranceType.CommercialPackage]: InsuranceTypeShared.COMMERCIAL_PACKAGE,
	[InsuranceType.LiquorLiability]: InsuranceTypeShared.LIQUOR_LIABILITY,
	[InsuranceType.ExcessLiability]: InsuranceTypeShared.EXCESS_LIABILITY,
	[InsuranceType.CommercialInlandMarine]: InsuranceTypeShared.COMMERCIAL_INLAND_MARINE,
	[InsuranceType.OceanMarine]: InsuranceTypeShared.OCEAN_MARINE,
	[InsuranceType.PolutionLiability]: InsuranceTypeShared.POLUTION_LIABILITY,
	[InsuranceType.Bond]: InsuranceTypeShared.BOND,
	[InsuranceType.BondFidelity]: InsuranceTypeShared.BOND_FIDELITY,
	[InsuranceType.BondFiduciary]: InsuranceTypeShared.BOND_FIDUCIARY,
	[InsuranceType.BondSurety]: InsuranceTypeShared.BOND_SURETY,
	[InsuranceType.Crime]: InsuranceTypeShared.CRIME,
	[InsuranceType.CyberLiability]: InsuranceTypeShared.CYBER_LIABILITY,
	[InsuranceType.GarageAndDealers]: InsuranceTypeShared.GARAGE_AND_DEALERS,
	[InsuranceType.GarageKeepersLegalLiability]: InsuranceTypeShared.GARAGE_KEEPERS_LEGAL_LIABILITY,
	[InsuranceType.InstallationBuildersRisk]: InsuranceTypeShared.INSTALLATION_BUILDERS_RISK,
	[InsuranceType.ShortTermDisability]: InsuranceTypeShared.SHORT_TERM_DISABILITY,
	[InsuranceType.EmployeeBenefitsLiability]: InsuranceTypeShared.EMPLOYEE_BENEFITS_LIABILITY,
	[InsuranceType.EquipmentBreakdown]: InsuranceTypeShared.EQUIPMENT_BREAKDOWN,
	[InsuranceType.BusinessInterruption]: InsuranceTypeShared.BUSINESS_INTERRUPTION,
	[InsuranceType.HiredAndNonOwnedAuto]: InsuranceTypeShared.HIRED_AND_NON_OWNED_AUTO,
	[InsuranceType.MedicalMalpractice]: InsuranceTypeShared.MEDICAL_MALPRACTICE,
	[InsuranceType.ProductLiability]: InsuranceTypeShared.PRODUCT_LIABILITY,
	[InsuranceType.FiduciaryLiability]: InsuranceTypeShared.FIDUCIARY_LIABILITY,
	[InsuranceType.Habitational]: InsuranceTypeShared.HABITATIONAL,
	[InsuranceType.OwnersAndContractorsProtectiveLiability]:
		InsuranceTypeShared.OWNERS_AND_CONTRACTORS_PROTECTIVE_LIABILITY,
	[InsuranceType.FireBoilerAndMachinery]: InsuranceTypeShared.FIRE_BOILER_AND_MACHINERY,
	[InsuranceType.RentGuarantee]: InsuranceTypeShared.RENT_GUARANTEE,
	[InsuranceType.PersonalPackage]: InsuranceTypeShared.PERSONAL_PACKAGE,
	[InsuranceType.CommercialFleet]: InsuranceTypeShared.COMMERCIAL_FLEET,
	[InsuranceType.CommercialWholesaleBrokers]: InsuranceTypeShared.COMMERCIAL_WHOLESALE_BROKERS,
	[InsuranceType.ExcessAndSurplusCommercialCasualty]:
		InsuranceTypeShared.EXCESS_AND_SURPLUS_COMMERCIAL_CASUALTY,
	[InsuranceType.Powersports]: InsuranceTypeShared.POWERSPORTS,
	[InsuranceType.CollectorsVehicle]: InsuranceTypeShared.COLLECTORS_VEHICLE,
	[InsuranceType.Yacht]: InsuranceTypeShared.YACHT,
	[InsuranceType.VacantHomeowners]: InsuranceTypeShared.VACANT_HOMEOWNERS,
	[InsuranceType.TravelTrailer]: InsuranceTypeShared.TRAVEL_TRAILER,
	[InsuranceType.Watercraft]: InsuranceTypeShared.WATERCRAFT,
	[InsuranceType.OffRoadVehicle]: InsuranceTypeShared.OFF_ROAD_VEHICLE,
	[InsuranceType.ManufacturedHome]: InsuranceTypeShared.MANUFACTURED_HOME,
	[InsuranceType.SpecialEvents]: InsuranceTypeShared.SPECIAL_EVENTS,
	[InsuranceType.SpecialtyCommercial]: InsuranceTypeShared.SPECIALTY_COMMERCIAL,
	[InsuranceType.ResidentialFlood]: InsuranceTypeShared.RESIDENTIAL_FLOOD,
	[InsuranceType.CommercialFlood]: InsuranceTypeShared.COMMERCIAL_FLOOD,
	[InsuranceType.ParametricEarthquake]: InsuranceTypeShared.PARAMETRIC_EARTHQUAKE,
	[InsuranceType.HomeAndElectronicProtection]: InsuranceTypeShared.HOME_AND_ELECTRONICS_PROTECTION,
	[InsuranceType.RecreationalVehicles]: InsuranceTypeShared.RECREATIONAL_VEHICLES
};

export const parseInsuranceTypeToProto = (type: InsuranceType) => {
	return insuranceTypeToServiceProtoMapping[type];
};

export const insuranceTypeFromWorkItemServiceProtoMapping: { [key: string]: InsuranceType } = {
	['ANTIQUE_CLASSIC_AUTO']: InsuranceType.AntiqueClassicAuto,
	['ATV_OFF_ROAD_VEHICLE']: InsuranceType.AtvOffRoadVehicle,
	['BOAT']: InsuranceType.Boat,
	['COMMERCIAL_AUTO']: InsuranceType.CommercialAuto,
	['CONDO']: InsuranceType.Condo,
	['DENTAL']: InsuranceType.Dental,
	['DWELLING_FIRE']: InsuranceType.DwellingFire,
	['EARTHQUAKE']: InsuranceType.Earthquake,
	['ELECTRONIC_DEVICES']: InsuranceType.ElectronicDevices,
	['EVENT_LIABILITY']: InsuranceType.EventLiability,
	['FLOOD']: InsuranceType.Flood,
	['HEALTH']: InsuranceType.Health,
	['HOMEOWNERS']: InsuranceType.Homeonwners,
	['IDENTITY_PROTECTION']: InsuranceType.IdentityProtection,
	['LANDLORD']: InsuranceType.Landlord,
	['LIFE']: InsuranceType.Life,
	['MEDICARE']: InsuranceType.Medicare,
	['MOBILE_HOMEOWNERS']: InsuranceType.MobileHomeowners,
	['MOTORCYCLE']: InsuranceType.Motorcycle,
	['OTHER']: InsuranceType.Other,
	['PERSONAL_AUTO']: InsuranceType.PersonalAuto,
	['PERSONAL_UMBRELLA']: InsuranceType.PersonalUmbrella,
	['PERSONAL_WIND']: InsuranceType.PersonalWind,
	['PET']: InsuranceType.Pet,
	['RENTER']: InsuranceType.Renter,
	['RIDESHARING']: InsuranceType.Ridesharing,
	['RV_MOTORHOME']: InsuranceType.RvMotorhome,
	['SCHEDULED_PERSONAL_PROPERTY']: InsuranceType.ScheduledPersonalProperty,
	['SNOWMOBILE']: InsuranceType.Snowmobile,
	['TRAVEL']: InsuranceType.Travel,
	['VISION']: InsuranceType.Vision,
	['COMMERCIAL']: InsuranceType.Commercial,
	['INLAND_MARINE']: InsuranceType.InlandMarine,
	['MULTILINE']: InsuranceType.Multiline,
	['PROFESSIONAL_LIABILITY']: InsuranceType.ProfessionalLiability,
	['GENERAL_LIABILITY']: InsuranceType.GeneralLiability,
	['BUSINESS_OWNER_POLICY']: InsuranceType.BusinessOwnerPolicy,
	['WORKERS_COMPENSATION']: InsuranceType.WorkersCompesation,
	['COMMERCIAL_PROPERTY']: InsuranceType.CommercialProperty,
	['COMMERCIAL_UMBRELLA']: InsuranceType.CommercialUmbrella,
	['DIRECTORS_AND_OFFICERS']: InsuranceType.DirectorsAndOfficers,
	['EMPLOYMENT_PRACTICES_LIABILITY']: InsuranceType.EmploymentPracticesLiability,
	['ERRORS_AND_OMISSIONS']: InsuranceType.ErrorsAndOmissions,
	['COMMERCIAL_PACKAGE']: InsuranceType.CommercialPackage,
	['LIQUOR_LIABILITY']: InsuranceType.LiquorLiability,
	['EXCESS_LIABILITY']: InsuranceType.ExcessLiability,
	['COMMERCIAL_INLAND_MARINE']: InsuranceType.CommercialInlandMarine,
	['OCEAN_MARINE']: InsuranceType.OceanMarine,
	['POLUTION_LIABILITY']: InsuranceType.PolutionLiability,
	['BOND']: InsuranceType.Bond,
	['BOND_FIDELITY']: InsuranceType.BondFidelity,
	['BOND_FIDUCIARY']: InsuranceType.BondFiduciary,
	['BOND_SURETY']: InsuranceType.BondSurety,
	['CRIME']: InsuranceType.Crime,
	['CYBER_LIABILITY']: InsuranceType.CyberLiability,
	['GARAGE_AND_DEALERS']: InsuranceType.GarageAndDealers,
	['GARAGE_KEEPERS_LEGAL_LIABILITY']: InsuranceType.GarageKeepersLegalLiability,
	['INSTALLATION_BUILDERS_RISK']: InsuranceType.InstallationBuildersRisk,
	['SHORT_TERM_DISABILITY']: InsuranceType.ShortTermDisability,
	['EMPLOYEE_BENEFITS_LIABILITY']: InsuranceType.EmployeeBenefitsLiability,
	['EQUIPMENT_BREAKDOWN']: InsuranceType.EquipmentBreakdown,
	['BUSINESS_INTERRUPTION']: InsuranceType.BusinessInterruption,
	['HIRED_AND_NON_OWNED_AUTO']: InsuranceType.HiredAndNonOwnedAuto,
	['MEDICAL_MALPRACTICE']: InsuranceType.MedicalMalpractice,
	['PRODUCT_LIABILITY']: InsuranceType.ProductLiability,
	['FIDUCIARY_LIABILITY']: InsuranceType.FiduciaryLiability,
	['HABITATIONAL']: InsuranceType.Habitational,
	['OWNERS_AND_CONTRACTORS_PROTECTIVE_LIABILITY']:
		InsuranceType.OwnersAndContractorsProtectiveLiability,
	['FIRE_BOILER_AND_MACHINERY']: InsuranceType.FireBoilerAndMachinery,
	['RENT_GUARANTEE']: InsuranceType.RentGuarantee,
	['PERSONAL_PACKAGE']: InsuranceType.PersonalPackage,
	['COMMERCIAL_FLEET']: InsuranceType.CommercialFleet,
	['COMMERCIAL_WHOLESALE_BROKERS']: InsuranceType.CommercialWholesaleBrokers,
	['MANUFACTURED_HOME']: InsuranceType.ManufacturedHome
};

export const parseInsuranceTypeFromWorkItemService = (lob: string) =>
	insuranceTypeFromWorkItemServiceProtoMapping[lob];
