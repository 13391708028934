import { QualificationStatus } from '@agentero/grpc-clients/grpc/portal-fe';

const bwaQualificationMapping: Record<QualificationStatus, boolean | undefined> = {
	[QualificationStatus.QUALIFICATION_STATUS_UNSPECIFIED]: undefined,
	[QualificationStatus.QUALIFICATION_STATUS_QUALIFIED]: true,
	[QualificationStatus.QUALIFICATION_STATUS_PENDING_QUALIFICATION]: true,
	[QualificationStatus.QUALIFICATION_STATUS_NOT_QUALIFIED]: false
};

export const parseBwAQualification = (bwaQualification: QualificationStatus): boolean | undefined =>
	bwaQualificationMapping[bwaQualification];
