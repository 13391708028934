import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

import { handleGrpcResponse } from '@agentero/service-errors';

import { agentFrontendService } from 'packages/services/grpcClients';

import { AgenteroUserData } from '../../useAgQuery';

export const fetchAgentUIState = ({ token, userId }: AgenteroUserData) => {
	return new Promise<Record<string, boolean | string>>((resolve, reject) => {
		agentFrontendService.getAgentUIState(
			new Empty(),
			{ authorization: `Bearer ${token}` },
			(error, response) => {
				if (error) {
					handleGrpcResponse(reject, error, userId);
					return;
				}

				const json = response.toObject();

				resolve(
					json.state.fieldsMap.reduce((acc, item) => {
						return {
							...acc,
							[item[0]]: item[1].boolValue ?? item[1].stringValue
						};
					}, {})
				);
			}
		);
	});
};
