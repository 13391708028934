import { fullstoryTrackEvent } from './services/fullstory-service';

export type AgEvent = {
	eventName: string;
	properties: Record<string, unknown>;
};

export const useAgAnalytics = () => {
	const trackEvent = (event: AgEvent) => {
		fullstoryTrackEvent(event);
	};

	return { trackEvent };
};
