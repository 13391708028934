import { MutationConfig, UseAgMutationCallbacks, useAgMutation } from '../../useAgMutation';
import { MutateSendAgencyBwaTrainingArgs, sendAgencyBwaTraining } from './sendAgencyBwaTraining';

const sendAgencyBwaTrainingConfig: MutationConfig<MutateSendAgencyBwaTrainingArgs, void> = {
	mutation: (token, userId, data) => sendAgencyBwaTraining({ token, userId, ...data }),
	messages: {
		transKeys: ['shared'],
		success: t => t('statusUpdateSuccess'),
		error: () => 'shared:form.error'
	}
};
export const useAgencyBwaTrainingMutation = (callbacks?: UseAgMutationCallbacks) =>
	useAgMutation(sendAgencyBwaTrainingConfig, callbacks);
