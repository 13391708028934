import {
	CommunicationPreferences,
	GetSessionResponse
} from '@agentero/grpc-clients/grpc/portal-fe';

import { EOStatus, OperateAs, parseEOStatus, parseOperateAs } from '../agency';
import {
	ComplianceLicenseGlobalStatus,
	parseComplianceGlobalStatus
} from '../compliance/complianceLicense/ComplianceGlobalStatus';
import { InsuranceType, parseInsuranceTypeFromProto } from '../opportunity';
import { AgentSubscription, parseAgentSubscription } from './AgentSubscription';
import { Campaign } from './agentSubscription/Campaign';
import { parseBwAQualification } from './shared/BwAQualification';

export type AgentSession = {
	isCommercialRaterEnabled: boolean;
	appointmentsEnabled: boolean;
	quotableLobsList: InsuranceType[];
	compliance: {
		eoStatus: EOStatus;
		bwaQualified?: boolean;
		agencyLicenseStatus: ComplianceLicenseGlobalStatus;
		agencyAdminLicenseStatus: ComplianceLicenseGlobalStatus;
		agentLicenseStatus: ComplianceLicenseGlobalStatus;
		operateAs: OperateAs;
		agentResidentStatus: ComplianceLicenseGlobalStatus;
		agencyResidentStatus: ComplianceLicenseGlobalStatus;
	};
	subscription: AgentSubscription;
	campaign?: Campaign;
	communicationsPreferences: {
		email?: boolean;
		sms?: boolean;
	};
	hasAppointedTerminated: boolean;
};

export const parseAgentSession = (agentSession: GetSessionResponse.AsObject): AgentSession => {
	return {
		isCommercialRaterEnabled: agentSession.hasCommercialRater,
		appointmentsEnabled: agentSession.appointmentsEnabled,
		quotableLobsList: agentSession.quotableLobsList.map(parseInsuranceTypeFromProto),
		compliance: {
			eoStatus: parseEOStatus(agentSession.eAndOStatus),
			bwaQualified: parseBwAQualification(agentSession.bwaQualification),
			agencyLicenseStatus: parseComplianceGlobalStatus(agentSession.agencyLicenseStatus),
			agencyAdminLicenseStatus: parseComplianceGlobalStatus(agentSession.agencyAdminLicenseStatus),
			agentLicenseStatus: parseComplianceGlobalStatus(agentSession.agentLicenseStatus),
			operateAs: parseOperateAs(agentSession.howOperateAs),
			agentResidentStatus: parseComplianceGlobalStatus(agentSession.agentResidentLicenseStatus),
			agencyResidentStatus: parseComplianceGlobalStatus(agentSession.agencyResidentLicenseStatus)
		},
		subscription: parseAgentSubscription(agentSession.currentTier!),
		communicationsPreferences: parseCommunications(agentSession.communicationPreferences),
		hasAppointedTerminated: agentSession.hasTerminatedAppointments
	};
};

const parseCommunications = (
	communicationPreferences: CommunicationPreferences.AsObject | undefined
) => ({
	email: communicationPreferences?.email ?? undefined,
	sms: communicationPreferences?.sms ?? undefined
});
