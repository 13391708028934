import { agentUIStateKey } from 'packages/services/fetch/agent-fe/agent-ui-state/useAgentUIState';

import { MutationConfig, UseAgMutationCallbacks, useAgMutation } from '../../useAgMutation';
import { updateAgentUIState } from './updateAgentUIState';

type UpdateAgentLicenseConfig = MutationConfig<
	Omit<Record<string, string | boolean>, 'token' | 'userId'>,
	void
>;

export const updateAgentUIStateBaseConfig: Omit<UpdateAgentLicenseConfig, 'messages'> = {
	mutation: (token, userId, data) =>
		updateAgentUIState({
			token,
			userId,
			data
		}),
	invalidateQueries: () => [[agentUIStateKey]],
	removeQueries: () => [[agentUIStateKey]]
};

export const useAgentUIStateMutation = (callbacks?: UseAgMutationCallbacks) => {
	return useAgMutation(updateAgentUIStateBaseConfig, callbacks);
};
